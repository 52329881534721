/* eslint-disable react/prop-types */
import React from 'react';
import './Components.css';

function InputGroup(props) {
  return (
    <div className="field">
      <label className="label-element" htmlFor={props.id}>
        {props.label}{' '}
      </label>
      <input
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        type={props.type}
        className="input-element"
        placeholder={props.placeholder}
        disabled={props.disabled}
        style={props.style}
      />
    </div>
  );
}

export default InputGroup;
