/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

function Filter({ users, handleFilterInputChange }) {
  const { t } = useTranslation('common');

  return (
    <div className="filter-by-employee">
      <select
        onChange={handleFilterInputChange}
        className="select-filter"
        aria-label="Filter By Employee"
      >
        <option value="all">{t('commons.filter-by-employees')}</option>
        {users?.map((user) => (
          <option value={user.id} key={user.id}>
            {user.first_name} {user.last_name}
          </option>
        ))}
      </select>
      <span className="focus"></span>
    </div>
  );
}

export default Filter;
