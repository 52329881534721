import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

class HoursRegistrationService {
  getHourRegistrations() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/hour_registrations`, {
          params: {
            filtered: false,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  addHourRegistration(date, from, till, project_id, tickets, comment) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/api/v1/hour_registrations`, {
          date: date,
          from: from,
          till: till,
          project_id: project_id,
          tickets: tickets,
          comment: comment,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getRegistrationsOfProject(project_id, start_date, end_date) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_project`, {
          params: {
            project_id: project_id,
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getHourRegistrationById(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  getHoursOfUserOfProject(user_id, project_id, start_date, end_date) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_user_project`, {
          params: {
            user_id: user_id,
            project_id: project_id,
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  deleteHourRegistration(id) {
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/api/v1/hour_registrations/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  updateHourRegistration(id, date, from, till, project_id, tickets, comment) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/api/v1/hour_registrations/${id}`, {
          date: date,
          from: from,
          till: till,
          project_id: project_id,
          tickets: tickets,
          comment: comment,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getWorkedHoursOnProject(user_id, project_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/worked_hours_project`, {
          params: {
            user_id: user_id,
            project_id: project_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  getHoursOfUserFilter(user_id, start_date, end_date) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_user`, {
          params: {
            user_id: user_id,
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  getHoursOfCompanyFilter(start_date, end_date) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_company`, {
          params: {
            start_date: start_date,
            end_date: end_date,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  getHoursWeekOfUser(user_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/user_week`, {
          params: {
            user_id: user_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  exportCSV(startDate, endDate, type) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/export_hours.csv`, {
          params: {
            start_date: startDate.toISOString().slice(0, 10),
            end_date: endDate.toISOString().slice(0, 10),
            user_id: localStorage.getItem('employee'),
            project_id: localStorage.getItem('project'),
            type: type,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve('');
        });
    });
  }

  exportClientCSV(startDate, endDate, type, client_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/export_hours_client.csv`, {
          params: {
            start_date: startDate.toISOString().slice(0, 10),
            end_date: endDate.toISOString().slice(0, 10),
            user_id: localStorage.getItem('employee'),
            project_id: localStorage.getItem('project'),
            type: type,
            client_id: client_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve('');
        });
    });
  }

  getClientRegistrations(client_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/client_registrations`, {
          params: {
            client_id: client_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  /* CLIENT FILTER */

  getClientHoursUserProject(client_id, user_id, project_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_user_project_client`, {
          params: {
            client_id: client_id,
            user_id: user_id,
            project_id: project_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getClientHoursUser(client_id, user_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_user_client`, {
          params: {
            client_id: client_id,
            user_id: user_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getClientHoursProject(client_id, project_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/hours_of_project_client`, {
          params: {
            client_id: client_id,
            project_id: project_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }
}

export default new HoursRegistrationService();
