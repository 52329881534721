/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ClientService from '../../services/ClientService';
import InputGroup from '../../components/InputGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalAdd({ closeModal }) {
  const [name, setName] = useState('');

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleNameInputChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name) {
      setValid(true);
      setMessage(await ClientService.addClient(name));
    } else {
      setMessage('Please enter the name of the client!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-client">
          {t('clients.header-add')}{' '}
          <CloseButton closeModal={closeModal} style={{ marginTop: '-2%' }} />
        </h3>
        {submitted && valid ? (
          <div className="success-message-add-client">{message}</div>
        ) : null}
        {submitted && !valid ? (
          <div className="error-message-add-client">{message}</div>
        ) : null}
        <InputGroup
          id="add-client"
          label={t('clients.name')}
          onChange={handleNameInputChange}
          value={name}
          name="add-client"
          type="text"
          placeholder="Enter the name"
        />
        <SubmitButton text={t('clients.button-add')} />
      </form>
    </Box>
  );
}

export default ModalAdd;
