import axios from 'axios';

// eslint-disable-next-line no-undef
const API_URL = process.env.REACT_APP_API_URL;
class ClientService {
  getAllClients() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/clients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getClientById(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/clients/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  updateClient(id, name) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/clients`, {
          id: id,
          name: name,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  addClient(name) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/clients`, {
          name: name,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            resolve('The client already exists');
          }
          resolve(error.message);
        });
    });
  }

  removeClient(id) {
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/clients/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getClientsOrderedAlphabetically() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/clients/order_alphabetically`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }
}
export default new ClientService();
