/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import '../UserManagement.css';
import UserService from '../../services/UserService';
import InputGroup from '../../components/InputGroup';
import SelectGroup from '../../components/SelectGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalFormAdd(props) {
  const [employee, setEmployee] = useState(Object);
  const [existingVacationHours, setExistingVacationHours] = useState(0);
  const [existingLastWorkingDay, setExistingLastWorkingDay] = useState(0);

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const employee = await UserService.getUserById(props.id);
      setEmployee(employee);
      setExistingLastWorkingDay(employee.last_working_day);
      setExistingVacationHours(employee.vacation_hours);
    })();
  }, []);

  function convertLastWorkingDay() {
    switch (existingLastWorkingDay) {
      case 'monday':
        return 0;
      case 'tuesday':
        return 1;
      case 'wednesday':
        return 2;
      case 'thursday':
        return 3;
      case 'friday':
        return 4;
      default:
        return 0;
    }
  }

  const options = [
    { value: existingVacationHours, label: `${existingVacationHours / 8}` },
    { value: 8, label: '1' },
    { value: 80, label: '10' },
    { value: 120, label: '15' },
    { value: 160, label: '20' },
    { value: 240, label: '30' },
  ];

  const selectLastWorkingDay = [
    { value: convertLastWorkingDay(), label: `${existingLastWorkingDay}` },
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
  ];

  const handleFirstNameInputChange = (event) => {
    setEmployee({ ...employee, first_name: event.target.value });
  };

  const handleLastNameInputChange = (event) => {
    setEmployee({ ...employee, last_name: event.target.value });
  };

  const handleVacationHoursInputChange = (event) => {
    setEmployee((prev) => ({ ...prev, vacation_hours: event }));
  };

  const handleLastWorkingDayInputChange = (event) => {
    setEmployee((prev) => ({ ...prev, last_working_day: event }));
  };

  const handleSlackAccountInputChange = (event) => {
    setEmployee({ ...employee, slack_account: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      employee.first_name &&
      employee.last_name &&
      employee.slack_account &&
      employee.vacation_hours
    ) {
      setValid(true);
      const response = await UserService.editUser(
        employee.id,
        employee.first_name,
        employee.last_name,
        employee.vacation_hours.value,
        employee.last_working_day.value,
        employee.slack_account
      );
      if (response.updated === false) {
        setValid(false);
        setMessage(response.message);
      } else {
        props.setSnackbarMessage(response.message);
        props.closeModal();
        props.openSnackbar();
      }
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          Edit Employee Information{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '-1%' }}
          />
        </h3>
        {submitted && !valid ? (
          <div className="error-message-edit-employee">{message}</div>
        ) : null}
        <InputGroup
          id="add-first-name"
          label={t('users.first-name')}
          onChange={handleFirstNameInputChange}
          value={employee.first_name || ''}
          name="add-first-name"
          type="text"
          placeholder="Enter the first name"
        />
        <InputGroup
          id="add-last-name"
          label={t('users.last-name')}
          onChange={handleLastNameInputChange}
          value={employee.last_name}
          name="add-last-name"
          type="text"
          placeholder="Enter the last name"
        />
        <InputGroup
          id="add-email"
          label={t('users.email') + '*'}
          value={employee.email}
          name="add-email"
          type="text"
        />
        <InputGroup
          id="add-slack-account"
          label={t('users.slack-account')}
          onChange={handleSlackAccountInputChange}
          value={employee.slack_account || ''}
          name="add-slack-account"
          type="text"
          placeholder="Enter the Slack account ID"
        />
        <SelectGroup
          id="add-vacation-days"
          isMulti={false}
          name="add-vacation-days"
          onChange={handleVacationHoursInputChange}
          value={employee.vacation_hours}
          label={t('users.vacation-days')}
          options={options}
        />
        <SelectGroup
          id="add-last-day"
          isMulti={false}
          name="add-last-day"
          onChange={handleLastWorkingDayInputChange}
          value={employee.last_working_day}
          label={t('users.last-working-day')}
          options={selectLastWorkingDay}
        />
        <div className="edit-actions">
          <p className="not-editable">* {t('users.not-editable')}</p>
          <SubmitButton text={t('users.button-edit')} />
        </div>
      </form>
    </Box>
  );
}

export default ModalFormAdd;
