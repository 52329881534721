/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import './UserManagement.css';
import ModalFormAdd from './Modals/ModalFormAdd';
import { VscEdit, VscTrash } from 'react-icons/vsc';
import ModalFormEdit from './Modals/ModalFormEdit';
import UserService from '../services/UserService';
import ModalFormDelete from './Modals/ModalFormDelete';
import ModalFormInfo from './Modals/ModalFormInfo';
import { BsInfoLg } from 'react-icons/bs';
import FilterUsers from './Filter/FilterUsers';
import SnackbarComponent from '../components/Snackbar';
import { useTranslation } from 'react-i18next';

function UserManagement() {
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { t } = useTranslation('common');

  const [openFilterList, setOpenFilterList] = useState(false);
  const handleOpenFilterList = () => setOpenFilterList(true);
  const handleCloseFilterList = async () => {
    setOpenFilterList(false);
    setUsers(await UserService.getAllEmployees());
  };

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleOpenInfo = () => setOpenModalInfo(true);
  const handleCloseInfo = async () => {
    setOpenModalInfo(false);
  };

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const handleOpenAdd = () => setOpenModalAdd(true);
  const handleCloseAdd = async () => {
    setOpenModalAdd(false);
    setOpenFilterList(false);
    setUsers(await UserService.getAllEmployees());
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenEdit = () => setOpenModalEdit(true);
  const handleCloseEdit = async () => {
    setOpenModalEdit(false);
    localStorage.removeItem('id');
    setOpenFilterList(false);
    setUsers(await UserService.getAllEmployees());
  };

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const handleOpenDelete = () => setOpenModalDelete(true);
  const handleCloseDelete = async () => {
    setOpenModalDelete(false);
    localStorage.removeItem('id');
    setOpenFilterList(false);
    setUsers(await UserService.getAllEmployees());
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => setOpenSnackbar(true);
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    void (async () => {
      setUsers(await UserService.getAllEmployees());
    })();
  }, []);

  return (
    <div>
      <div className="user-management">
        <div className="user-tables">
          <TableContainer component={Paper} className="table-container">
            <div style={{ backgroundColor: 'var(--table-bg-color)' }}>
              <FilterUsers
                setUsers={setUsers}
                openFilterList={openFilterList}
                setOpenFilterList={setOpenFilterList}
                handleOpenFilterList={handleOpenFilterList}
                handleCloseFilterList={handleCloseFilterList}
              />
              <button className="add-employee" onClick={handleOpenAdd}>
                {t('users.add-employee')}
              </button>
              <Modal
                open={openModalAdd}
                onClose={handleCloseAdd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ModalFormAdd
                  closeModal={handleCloseAdd}
                  openSnackbar={handleOpenSnackbar}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </Modal>
            </div>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ backgroundColor: 'var(--table-bg-color)' }}
            >
              <TableBody>
                {(rowsPerPage > 0
                  ? users.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : users
                ).map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{
                      '&:last-child td, &:last-child th': { borderBottom: 0 },
                    }}
                  >
                    <div className="user-container">
                      <div className="user-actions">
                        <h2 className="u-name">
                          {user.first_name} {user.last_name}
                        </h2>
                        <button
                          className="view-user-info"
                          onClick={() => {
                            handleOpenInfo();
                            localStorage.setItem('id', user.id);
                          }}
                        >
                          <BsInfoLg />
                        </button>
                        <Modal
                          open={openModalInfo}
                          onClose={handleCloseInfo}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <ModalFormInfo
                            id={localStorage.getItem('id')}
                            closeModal={handleCloseInfo}
                          />
                        </Modal>
                        <button
                          className="edit-employee"
                          onClick={() => {
                            handleOpenEdit();
                            localStorage.setItem('id', user.id);
                          }}
                        >
                          <VscEdit />
                        </button>
                        <Modal
                          open={openModalEdit}
                          onClose={handleCloseEdit}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <ModalFormEdit
                            id={localStorage.getItem('id')}
                            closeModal={handleCloseEdit}
                            openSnackbar={handleOpenSnackbar}
                            setSnackbarMessage={setSnackbarMessage}
                          />
                        </Modal>
                        <button
                          className="delete-employee"
                          onClick={() => {
                            handleOpenDelete();
                            localStorage.setItem('id', user.id);
                          }}
                        >
                          <VscTrash />
                        </button>
                        <Modal
                          open={openModalDelete}
                          onClose={handleCloseDelete}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <ModalFormDelete
                            id={localStorage.getItem('id')}
                            handleCloseDelete={handleCloseDelete}
                            openSnackbar={handleOpenSnackbar}
                            setSnackbarMessage={setSnackbarMessage}
                          />
                        </Modal>
                      </div>
                      <div className="u-accounts">
                        <p className="u-email">
                          {t('users.email')}: {user.email}
                        </p>
                        <p className="u-slack">
                          Slack ID: {user.slack_account}
                        </p>
                      </div>
                      <SnackbarComponent
                        open={openSnackbar}
                        handleClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        severity="success"
                      />
                    </div>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                      backgroundColor: 'var(--table-bg-color)',
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ color: 'var(--text-primary)' }}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
