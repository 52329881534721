/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import HourRegistrationService from '../../services/HourRegistrationService';
import ProjectService from '../../services/ProjectService';
import InputGroup from '../../components/InputGroup';
import SelectGroup from '../../components/SelectGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';

function HoursRegistration(props) {
  const [projects, setProjects] = useState([]);

  const [tickets, setTickets] = useState([]);

  const [selectValue, setSelectValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    date: new Date().toISOString().slice(0, 10),
    from: '09:00',
    till: '18:00',
    project: null,
    tickets: [],
    comment: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  function formatProjects(projects) {
    let options = [];
    for (const p of projects) {
      options.push({ value: p.id, label: p.title });
    }
    return options;
  }

  useEffect(() => {
    void (async () => {
      const projects = await ProjectService.getAllProjects();
      setProjects(projects);
    })();
  }, []);

  const handleDateInputChange = (event) => {
    setValues((prev) => ({ ...prev, date: event.target.value }));
  };

  const handleFromInputChange = (event) => {
    setValues((prev) => ({ ...prev, from: event.target.value }));
  };

  const handleTillInputChange = (event) => {
    setValues((prev) => ({ ...prev, till: event.target.value }));
  };

  const handleTicketInputChange = (event) => {
    setValues((prev) => ({ ...prev, tickets: event }));
    setSelectValue(event);
    setIsLoading(false);
  };

  const handleProjectInputChange = async (event) => {
    setIsLoading(true);
    setValues((prev) => ({ ...prev, project: event }));
    setTickets([]);
    setTickets(await ProjectService.getJiraTickets(event.value));
    setSelectValue('');
  };

  const handleCommentInputChange = (event) => {
    setValues((prev) => ({ ...prev, comment: event.target.value }));
  };

  function convertHourStringToSeconds(time) {
    const arr = time.split(':');
    const seconds = arr[0] * 3600 + arr[1] * 60;
    return seconds;
  }

  function formatArrayToString(allTickets) {
    let ticketsString = '';
    for (const t of allTickets) {
      ticketsString += t.label + ', ';
    }
    ticketsString = ticketsString.slice(0, -2);
    return ticketsString;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.date &&
      values.from &&
      values.till &&
      values.project &&
      values.tickets
    ) {
      if (
        values.tickets.length > 0 ||
        values.comment ||
        (values.tickets.length > 0 && values.comment)
      ) {
        try {
          await HourRegistrationService.addHourRegistration(
            values.date,
            convertHourStringToSeconds(values.from),
            convertHourStringToSeconds(values.till),
            values.project.value,
            formatArrayToString(values.tickets),
            values.comment
          );
          setValid(true);
          setMessage('The registration was successfully created!');
        } catch (error) {
          setValid(false);
          setMessage(error);
        }
        props.onSubmit();
        props.getUserWorkedHours();
      } else {
        setMessage('Please enter all the details!');
      }
    }
    setSubmitted(true);
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h3 className="new-hr">{t('hour-registration-form.header')}</h3>
      {submitted && valid ? <div className="hr-success">{message}</div> : null}
      {submitted && !valid ? <div className="hr-error">{message}</div> : null}
      <InputGroup
        id="hr-date"
        label={t('commons.date')}
        onChange={handleDateInputChange}
        value={values.date}
        name="hr-date"
        type="date"
      />
      <InputGroup
        id="hr-from"
        label={t('commons.from')}
        onChange={handleFromInputChange}
        value={values.from}
        name="hr-from"
        type="time"
      />
      <InputGroup
        id="hr-till"
        label={t('commons.until')}
        onChange={handleTillInputChange}
        value={values.till}
        name="hr-till"
        type="time"
      />
      <SelectGroup
        id="hr-projects"
        isMulti={false}
        name="hr-projects"
        onChange={handleProjectInputChange}
        value={values.project}
        label={t('hour-registration-form.project')}
        options={formatProjects(projects)}
      />
      <SelectGroup
        id="hr-tickets"
        isMulti={true}
        name="hr-tickets"
        onChange={handleTicketInputChange}
        value={selectValue}
        label={t('hour-registration-form.tickets')}
        options={tickets}
        isLoading={isLoading}
      />
      <InputGroup
        id="hr-comment"
        label={t('hour-registration-form.comment')}
        onChange={handleCommentInputChange}
        value={values.comment}
        name="hr-comment"
        type="text"
        placeholder={'Comment...'}
      />
      <SubmitButton text={t('commons.button-text')} />
    </form>
  );
}

export default HoursRegistration;
