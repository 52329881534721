/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ProjectService from '../../services/ProjectService';
import SelectGroup from '../../components/SelectGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalAddContributor(props) {
  const [project, setProject] = useState(Object);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState({
    user_id: 0,
    project_id: props.id,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const project = await ProjectService.getProjectById(props.id);
      setProject(project);
      const users = await ProjectService.getPossibleContributorsForProject(
        project.id
      );
      setUsers(users);
      setValues((prev) => ({
        ...prev,
        project_id: project.id,
      }));
      setIsLoading(false);
    })();
  }, []);

  function formatEmployees(users) {
    let options = [];
    for (const u of users) {
      options.push({ value: u.id, label: u.first_name + ' ' + u.last_name });
    }
    return options;
  }

  const handleEmployeeInputChange = (event) => {
    setValues((prev) => ({ ...prev, user_id: event }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.user_id) {
      setValid(true);
      setMessage(
        await ProjectService.addUserToProject(
          values.user_id.value,
          values.project_id
        )
      );
    } else {
      setMessage('Please select an employee!');
    }
    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('projects.header-add-contributor')} {project.title}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '-1%' }}
          />
        </h3>
        {submitted && valid ? (
          <div className="success-message-add-contributor">{message}</div>
        ) : null}
        {submitted && !valid ? (
          <div className="error-message-add-contributor">{message}</div>
        ) : null}
        {!isLoading ? (
          users.length > 0 ? (
            <>
              <div className="info-modal-form">
                <SelectGroup
                  id="add-project-contributor"
                  isMulti={false}
                  name="add-project-contributort"
                  onChange={handleEmployeeInputChange}
                  value={values.user_id}
                  label={t('projects.employee')}
                  options={formatEmployees(users)}
                />
              </div>
              <SubmitButton text={t('projects.button-add-contributor')} />
            </>
          ) : (
            <p className="no-contributors-found">
              {t('projects.no-contributors')}
            </p>
          )
        ) : null}
      </form>
    </Box>
  );
}

export default ModalAddContributor;
