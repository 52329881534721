/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';
import './Components.css';

function SelectGroup(props) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 'fit-content',
      minHeight: 25,
      fontSize: '12px',
      width: '95%',
      color: 'var(--input-color)',
      backgroundColor: 'var(--input-bg-color)',
      border: '1px solid var(--input-border-color)',
    }),
  };
  return (
    <div className="field">
      <label className="label-select-element" htmlFor={props.id}>
        {props.label}{' '}
      </label>
      <Select
        styles={customStyles}
        id={props.id}
        isMulti={props.isMulti}
        name={props.name}
        value={props.value}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={props.onChange}
        noOptionsMessage={() => 'No Options'}
        loadingMessage={() => 'Loading...'}
        isLoading={props.isLoading}
        components={{ LoadingIndicator: null }}
        defaultValue={props.defaultValue}
      />
    </div>
  );
}

export default SelectGroup;
