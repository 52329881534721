/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CalendarUtils from '../../Dashboard/Calendar/CalendarUtils';
import { TableFooter, TablePagination } from '@mui/material';
import LeaveRequestService from '../../services/LeaveRequestService';
import { useTranslation } from 'react-i18next';
import AuthenticationService from '../../services/AuthenticationService';

function LeaveRequestsUser() {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const user_id = AuthenticationService.getCurrentUser().user_id;

  const { t } = useTranslation('common');

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leaveRequests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerStyling = {
    color: 'var(--text-primary)',
    fontWeight: '550',
    fontFamily: 'Lato',
    backgroundColor: 'var(--table-bg-color',
  };

  useEffect(() => {
    void (async () => {
      const accepted =
        await LeaveRequestService.getLeaveRequestsByStatusOfCurrentUser(
          user_id,
          'accepted'
        );
      const pending =
        await LeaveRequestService.getLeaveRequestsByStatusOfCurrentUser(
          user_id,
          'pending'
        );
      setLeaveRequests(accepted.concat(pending));
    })();
  }, []);

  return (
    <div className="pp-leave-table">
      <div className="blue-header-pp-leaves">
        {t('leave-requests.header-my-overview')}
      </div>
      {leaveRequests.length > 0 ? (
        <TableContainer
          component={Paper}
          className="table-container"
          sx={{
            borderRadius: '0px',
            backgroundColor: 'var(--table-bg-color)',
            color: 'var(--text-primary)',
            border: '.5px solid var(--table-boder-color)',
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={headerStyling}>
                  {t('leave-requests.period')}
                </TableCell>
                <TableCell style={headerStyling}>
                  {t('commons.reason')}
                </TableCell>
                <TableCell style={headerStyling}>
                  {t('leave-requests.status')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ color: 'var(--text-primary)' }}>
              {(rowsPerPage > 0
                ? leaveRequests.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : leaveRequests
              ).map((leaveRequest) => (
                <TableRow
                  style={{ fontSize: '12px' }}
                  key={leaveRequest.id}
                  className="leave-requests-row"
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    color: 'var(--text-primary)',
                  }}
                >
                  {leaveRequest.end_date !== null ? (
                    <TableCell className="table-cell-pp">
                      {new Date(leaveRequest.start_date)
                        .toUTCString()
                        .slice(0, 16)}{' '}
                      -{' '}
                      {new Date(leaveRequest.end_date)
                        .toUTCString()
                        .slice(0, 16)}
                    </TableCell>
                  ) : (
                    <TableCell className="table-cell-pp">
                      {new Date(leaveRequest.start_date)
                        .toUTCString()
                        .slice(0, 16)}
                      , {CalendarUtils.showMinutes(leaveRequest.start_time)} -{' '}
                      {CalendarUtils.showMinutes(leaveRequest.end_time)}
                    </TableCell>
                  )}
                  <TableCell className="table-cell-pp">
                    {leaveRequest.leave_type}
                  </TableCell>
                  {leaveRequest.status === 'accepted' ? (
                    <TableCell className="table-cell-pp">
                      <p className="pp-status-a">{leaveRequest.status}</p>
                    </TableCell>
                  ) : (
                    <TableCell className="table-cell-pp">
                      <p className="pp-status-p">{leaveRequest.status}</p>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                    backgroundColor: 'var(--table-bg-color)',
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[2, 5, 10]}
                component="div"
                count={leaveRequests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  backgroundColor: '#3474E68',
                  color: 'var(--text-primary)',
                }}
              />
            </TableRow>
          </TableFooter>
        </TableContainer>
      ) : (
        <div className="no-leave-requests">
          <p>{t('leave-requests.no-leave-requests')}</p>
        </div>
      )}
    </div>
  );
}

export default LeaveRequestsUser;
