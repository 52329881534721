/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import '../UserManagement.css';
import UserService from '../../services/UserService';
import InputGroup from '../../components/InputGroup';
import SelectGroup from '../../components/SelectGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalFormAdd(props) {
  const selectVacationDays = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 },
    { value: 30, label: 30 },
  ];

  const selectLastWorkingDay = [
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
  ];

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    vacation_days: 1,
    last_working_day: 0,
    slack_account: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleFirstNameInputChange = (event) => {
    setValues({ ...values, firstName: event.target.value });
  };

  const handleLastNameInputChange = (event) => {
    setValues({ ...values, lastName: event.target.value });
  };

  const handleEmailInputChange = (event) => {
    setValues({ ...values, email: event.target.value });
  };

  const handleVacationDaysInputChange = (event) => {
    setValues((prev) => ({ ...prev, vacation_days: event }));
  };

  const handleLastWorkingDayInputChange = (event) => {
    setValues((prev) => ({ ...prev, last_working_day: event }));
  };

  const handleSlackAccountInputChange = (event) => {
    setValues({ ...values, slack_account: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.firstName &&
      values.lastName &&
      values.email &&
      values.slack_account
    ) {
      setValid(true);
      const response = await UserService.addNewUser(
        values.firstName,
        values.lastName,
        values.email,
        values.vacation_days.value * 8,
        values.last_working_day.value,
        values.slack_account,
        null,
        0
      );
      if (response.created === false) {
        setValid(false);
        setMessage(response.message.email[0]);
      } else {
        props.setSnackbarMessage(response.message);
        props.closeModal();
        props.openSnackbar();
      }
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('users.header-add')}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '-1%' }}
          />
        </h3>
        {submitted && !valid ? (
          <div className="error-message-add-employee">{message}</div>
        ) : null}
        <InputGroup
          id="add-first-name"
          label={t('users.first-name')}
          onChange={handleFirstNameInputChange}
          value={values.firstName}
          name="add-first-name"
          type="text"
          placeholder="Enter the first name"
        />
        <InputGroup
          id="add-last-name"
          label={t('users.last-name')}
          onChange={handleLastNameInputChange}
          value={values.lastName}
          name="add-last-name"
          type="text"
          placeholder="Enter the last name"
        />
        <InputGroup
          id="add-email"
          label={t('users.email')}
          onChange={handleEmailInputChange}
          value={values.email}
          name="add-email"
          type="text"
          placeholder="Enter the e-mail address"
        />
        <InputGroup
          id="add-slack-account"
          label={t('users.slack-account')}
          onChange={handleSlackAccountInputChange}
          value={values.slack_account}
          name="add-slack-account"
          type="text"
          placeholder="Enter the Slack account ID"
        />
        <SelectGroup
          id="add-vacation-days"
          isMulti={false}
          name="add-vacation-days"
          onChange={handleVacationDaysInputChange}
          value={values.vacation_days}
          label={t('users.vacation-days')}
          options={selectVacationDays}
        />
        <SelectGroup
          id="add-last-day"
          isMulti={false}
          name="add-last-day"
          onChange={handleLastWorkingDayInputChange}
          value={values.last_working_day}
          label={t('users.last-working-day')}
          options={selectLastWorkingDay}
        />
        <SubmitButton text={t('users.button-add')} />
      </form>
    </Box>
  );
}

export default ModalFormAdd;
