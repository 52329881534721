import axios from 'axios';
import AuthenticationService from './AuthenticationService';

// eslint-disable-next-line no-undef
const API_URL = process.env.REACT_APP_API_URL;

class ProjectService {
  getAllProjects() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getFirstProject() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects`)
        .then((response) => {
          resolve(response.data[0].id);
        })
        .catch(() => {
          resolve(0);
        });
    });
  }

  getProjectById(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    });
  }

  getProjectTitleOfHourRegistration(idHourReg) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/hour_registrations/${idHourReg}`, {
          id: idHourReg,
        })
        .then((response) => {
          axios
            .get(`${API_URL}/projects/${response.data.project_id}`, {
              id: response.data.project_id,
            })
            .then((response) => {
              resolve(response.data.title);
            })
            .catch(() => {
              resolve('');
            });
        })
        .catch(() => {
          resolve('');
        });
    });
  }

  getJiraTickets(id) {
    let options = [];
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/${id}`, {
          id: id,
        })
        .then((response) => {
          axios
            .get(`${API_URL}/projects/jira_tickets`, {
              params: {
                jira_name: response.data.jira_name,
              },
            })
            .then((response) => {
              for (const i of response.data.issues) {
                options.push({
                  value: i.id,
                  label: `${i.key}: ${i.fields.summary} `,
                });
              }
              resolve(options);
            })
            .catch(() => {
              resolve([]);
            });
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  updateProject(id, description, jira_link, repo_link, jira_name) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/projects`, {
          id: id,
          description: description,
          jira_link: jira_link,
          repo_link: repo_link,
          jira_name: jira_name,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  addNewProject(
    title,
    client_id,
    description,
    jira_link,
    repo_link,
    left_hours,
    jira_name
  ) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/projects`, {
          title: title,
          client_id: client_id,
          description: description,
          jira_link: jira_link,
          repo_link: repo_link,
          left_hours: left_hours,
          worked_hours: 0,
          jira_name: jira_name,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getUsersOfProject(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data.users);
        })
        .catch((error) => {
          console.log('Error', error.message);
        });
    });
  }

  getFilteredProjectsField(field) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/filter_by_field`, {
          params: { field: field },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getFilteredProjectsByContributors() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/filter_by_contributors`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getRecentProjectsOfUser() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/recent_projects`, {
          params: { id: AuthenticationService.getCurrentUser().user_id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getClientProjects(client_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/projects/client_projects`, {
          params: {
            client_id: client_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  //ProjectUser

  addUserToProject(user_id, project_id) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/project_users`, {
          user_id: user_id,
          project_id: project_id,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getPossibleContributorsForProject(project_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/project_users/possible_contributors`, {
          params: {
            project_id: project_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }
}

export default new ProjectService();
