import HourRegistrationService from '../../services/HourRegistrationService';
import CalendarUtils from '../../Dashboard/Calendar/CalendarUtils';

class FilterUtils {
  /* ADMIN VIEW */

  async filterEmployeesProjects(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    employee_id,
    project_id,
    start_date,
    end_date
  ) {
    let filtered = await HourRegistrationService.getHoursOfUserOfProject(
      employee_id,
      project_id,
      start_date,
      end_date
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async filterEmployees(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    employee_id,
    start_date,
    end_date
  ) {
    let filtered = await HourRegistrationService.getHoursOfUserFilter(
      employee_id,
      start_date,
      end_date
    );

    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async filterProjects(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    project_id,
    start_date,
    end_date
  ) {
    let filtered = await HourRegistrationService.getRegistrationsOfProject(
      project_id,
      start_date,
      end_date
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async getCompanyStatistics(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    start_date,
    end_date
  ) {
    let all = await HourRegistrationService.getHoursOfCompanyFilter(
      start_date,
      end_date
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(all.hour_registrations)
    );
    setHoursWeek(all.hours_week);
    setHoursMonth(all.hours_month);
  }

  /* CLIENT VIEW */
  async filterEmployeesProjectsClient(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    employee_id,
    project_id,
    client_id
  ) {
    let filtered = await HourRegistrationService.getClientHoursUserProject(
      client_id,
      employee_id,
      project_id
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async filterEmployeesClient(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    employee_id,
    client_id
  ) {
    let filtered = await HourRegistrationService.getClientHoursUser(
      client_id,
      employee_id
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async filterProjectsClient(
    setEvents,
    setHoursWeek,
    setHoursMonth,
    project_id,
    client_id
  ) {
    let filtered = await HourRegistrationService.getClientHoursProject(
      client_id,
      project_id
    );
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(
        filtered.hour_registrations
      )
    );
    setHoursWeek(filtered.hours_week);
    setHoursMonth(filtered.hours_month);
  }

  async getClientStatistics(setEvents, setHoursWeek, setHoursMonth, client_id) {
    let all = await HourRegistrationService.getClientRegistrations(client_id);
    setEvents(
      await CalendarUtils.showEventsOnEmployeeCalendar(all.hour_registrations)
    );
    setHoursWeek(all.hours_week);
    setHoursMonth(all.hours_month);
  }
}
export default new FilterUtils();
