/* eslint-disable react/prop-types */
import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarComponent(props) {
  return (
    <div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          className="snackbar-component"
          open={props.open}
          autoHideDuration={6000}
          onClose={props.handleClose}
        >
          <Alert
            onClose={props.handleClose}
            severity={`${props.severity}`}
            sx={{ width: '100%' }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default SnackbarComponent;
