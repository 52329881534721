/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

function FilterByProject({ projects, handleFilterInputChange }) {
  const { t } = useTranslation('common');

  return (
    <div className="filter-by-project">
      <select
        onChange={handleFilterInputChange}
        className="select-filter"
        aria-label="Filter Registrations By Project"
      >
        <option value="all">{t('commons.filter-by-projects')}</option>
        {projects?.map((project) => (
          <option value={project.id} key={project.id}>
            {project.title}
          </option>
        ))}
      </select>
      <span className="focus"></span>
    </div>
  );
}

export default FilterByProject;
