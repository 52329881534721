const options = [
  {
    value: 1,
    label: 'First Name (A to Z)',
    field: 'first_name',
    type: 'string',
  },
  {
    value: 2,
    label: 'Last Name (A to Z)',
    field: 'last_name',
    type: 'string',
  },
  {
    value: 3,
    label: 'E-mail (A to Z)',
    field: 'email',
    type: 'string',
  },
  {
    value: 4,
    label: 'Vacation Hours (most to least)',
    field: 'vacation_hours',
    type: 'int',
  },
  {
    value: 5,
    label: 'Leave Requests (most to least)',
    field: 'leave_requests',
    type: 'array',
  },
  {
    value: 6,
    label: 'Projects contributing to (most to least)',
    field: 'project_users',
    type: 'array',
  },
];

export default options;
