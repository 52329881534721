import React, { useState, useEffect } from 'react';
import './ClientManagement.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ClientService from '../services/ClientService';
import ModalAdd from './Modals/ModalAdd';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import RowClient from './RowClient';

function ClientManagement() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [clients, setClients] = useState([]);

  const { t } = useTranslation('common');

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const handleOpenAdd = () => setOpenModalAdd(true);

  const handleCloseAdd = async () => {
    setOpenModalAdd(false);
    setClients(await ClientService.getAllClients());
  };

  const getClientsOrderedAlphabetically = async () => {
    setClients(await ClientService.getClientsOrderedAlphabetically());
  };

  useEffect(() => {
    void (async () => {
      setClients(await ClientService.getAllClients());
    })();
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clients.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="clients">
      <TableContainer
        component={Paper}
        className="clients-management"
        style={{ width: '100%' }}
        sx={{ backgroundColor: 'var(--table-bg-color)' }}
      >
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <div className="client-buttons-actions">
                <button
                  className="order-alphabetically"
                  onClick={getClientsOrderedAlphabetically}
                >
                  {t('clients.order-alphabetically')}
                </button>
                <button
                  className="add-client"
                  onClick={() => {
                    handleOpenAdd();
                  }}
                >
                  {t('clients.button-add')}
                </button>
                <Modal
                  open={openModalAdd}
                  onClose={handleCloseAdd}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <ModalAdd
                    onSubmit={async () =>
                      setClients(await ClientService.getAllClients())
                    }
                    closeModal={handleCloseAdd}
                  />
                </Modal>
              </div>
            </TableRow>
            {(rowsPerPage > 0
              ? clients.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : clients
            ).map((c) => (
              <RowClient
                key={c.id}
                id={c.id}
                name={c.name}
                projects={c.projects}
                length={c.projects.length}
                client={c}
                onSubmit={async () =>
                  setClients(await ClientService.getAllClients())
                }
              />
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                  backgroundColor: 'var(--table-bg-color)',
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={clients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ color: 'var(--text-primary)' }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ClientManagement;
