/* eslint-disable react/prop-types */
import React from 'react';

function SubmitButton(props) {
  return (
    <button
      type="submit"
      className="submit-button"
      style={props.style}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

export default SubmitButton;
