import axios from 'axios';
import AuthenticationService from './AuthenticationService';

// eslint-disable-next-line no-undef
const API_URL = process.env.REACT_APP_API_URL;
class LeaveRequestService {
  addLeaveRequest(start_date, end_date, type, start_time, end_time) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/leave_requests`, {
          start_date: start_date,
          end_date: end_date,
          user_id: AuthenticationService.getCurrentUser().user_id,
          leave_type: type,
          start_time: start_time,
          end_time: end_time,
        })
        .then((response) => {
          if (response.data.created) {
            resolve(response.data.message);
          } else {
            resolve(response.data.message.error[0]);
          }
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getAllLeaveRequests() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/leave_requests`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  updateStatus(id, status) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/leave_requests/update_status`, {
          id: id,
          leave_status: status,
          admin_id: AuthenticationService.getCurrentUser().user_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  updatePendingLeaveRequest(
    id,
    start_date,
    end_date,
    type,
    start_time,
    end_time
  ) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/leave_requests`, {
          id: id,
          start_date: start_date,
          end_date: end_date,
          user_id: AuthenticationService.getCurrentUser().user_id,
          leave_type: type,
          start_time: start_time,
          end_time: end_time,
        })
        .then((response) => {
          if (response.data.updated) {
            resolve(response.data.message);
          } else {
            resolve(response.data.message.error[0]);
          }
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getLeaveRequestById(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/leave_requests/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  getLeaveRequestsByStatusOfCurrentUser(id, status) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/leave_requests/by_status_of_user`, {
          params: {
            id: id,
            leave_status: status,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getLeaveRequestsByStatus(status, filtered = false) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/leave_requests`, {
          params: {
            filtered: filtered,
            by_status: status,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getLeaveRequestsStatus(status) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/leave_requests/by_status`, {
          params: {
            leave_status: status,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getFilteredLeaveRequestsByStartDate(start_date) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/leave_requests/filter_start_date`, {
          params: {
            start_date: start_date,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }
}

export default new LeaveRequestService();
