/* eslint-disable react/prop-types */
import React from 'react';

function LoginInput(props) {
  return (
    <div className="field">
      <label className="label-element-login" htmlFor={props.id}>
        {props.label}{' '}
      </label>
      <input
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        type={props.type}
        className="input-element-login"
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default LoginInput;
