import jwt from 'jwt-decode';
import axios from 'axios';

// eslint-disable-next-line no-undef
const API_URL = process.env.REACT_APP_API_URL;

class AuthenticationService {
  async checkTokenExp(navigate) {
    try {
      let token = localStorage.getItem('token');
      if (!token) throw new Error('no_token');

      if (Date.now() >= jwt(token).exp * 1000) {
        this.logOut();
        navigate('/');
        navigate(0);
      }
    } catch (error) {
      console.error('checkTokenExp', error);
      this.logOut();
      navigate('/');
      navigate(0);
    }
  }

  checkIfAdmin() {
    try {
      const decrypted_token = this.getUserToken();
      if (decrypted_token) {
        return decrypted_token.role === 'admin';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  checkIfClient() {
    try {
      const decrypted_token = this.getUserToken();
      if (decrypted_token) {
        return decrypted_token.role === 'client';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  getCurrentUser() {
    try {
      const decrypted_token = this.getUserToken();
      if (decrypted_token) {
        return decrypted_token;
      }
    } catch (error) {
      console.log('Error: ', error.message);
    }
  }

  async login(email, password) {
    const response = await axios.post(`${API_URL}/api/v1/sessions`, {
      email: email,
      password: password,
    });

    const { authorization } = response.headers;
    if (!authorization) throw new Error('invalid_credentials');

    localStorage.setItem('token', authorization);
    await this.checkTokenExp();
  }

  logOut() {
    localStorage.removeItem('token');
  }

  getUserToken() {
    const token = localStorage.getItem('token');

    if (!token) return;

    return jwt(token.split(' ')[1]);
  }
}
export default new AuthenticationService();
