import React, { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';
import LoginInput from '../components/LoginInput';
import { useTranslation } from 'react-i18next';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function Login() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleEmailInputChange = (event) => {
    setValues({ ...values, email: event.target.value });
  };

  const handlePasswordInputChange = (event) => {
    setValues({ ...values, password: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.email && values.password) {
      setValid(true);
      try {
        await AuthenticationService.login(values.email, values.password);
        if (isSafari) {
          // NOTE: `window.location` is used here instead of `navigate()` because of a bug in Safari,
          //       in which localStorage (and thus the token) is not preserved after login when using `navigate()`
          window.location.href = '/home';
          window.location.reload();
        } else {
          navigate('/home');
          navigate(0);
        }
      } catch (error) {
        if (error.message === 'invalid_credentials') {
          setMessage('Invalid Credentials');
        } else {
          console.error('Login Error', error);
          setMessage('Server Error');
        }
      }
    } else {
      setMessage('Please enter all the details');
    }

    setSubmitted(true);
  };

  useEffect(() => {
    if ('token' in localStorage) {
      navigate('/home');
    }
  }, []);

  return (
    <div className="login">
      <div className="blue-header"></div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-text">
          <h1>{t('login.log-in')}</h1>
          {submitted && valid ? (
            <div className="login-message">{message}</div>
          ) : null}
          {submitted && !valid ? (
            <div className="login-message">{message}</div>
          ) : null}
        </div>
        <LoginInput
          id="login-email"
          label={t('login.email')}
          onChange={handleEmailInputChange}
          value={values.email}
          name="login-email"
          type="text"
          placeholder={'voorbeeld@simdo.nl'}
        />
        <LoginInput
          id="login-password"
          label={t('login.password')}
          onChange={handlePasswordInputChange}
          value={values.password}
          name="login-password"
          type="password"
          placeholder={'******'}
        />
        <div className="login-action">
          <a href="/forgot-password" className="forgot-pwd">
            {t('login.forgot-password')}
          </a>
          <button type="submit" className="login-button">
            {t('login.button-text')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
