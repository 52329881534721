/* eslint-disable react/prop-types */
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SelectGroup from './SelectGroup';

function Filter(props) {
  return (
    <div>
      {props.openFilterList === true ? (
        <div className="filter-component">
          <IconButton
            onClick={props.handleCloseFilterList}
            style={{ color: 'var(--text-primary)' }}
          >
            <CloseIcon />
          </IconButton>
          <div className="selections">
            <SelectGroup
              id="hr-projects"
              isMulti={false}
              name="hr-projects"
              onChange={props.handleFilterInputChange}
              label={'Filter by'}
              options={props.options}
            />
          </div>
        </div>
      ) : (
        <div>
          <IconButton
            onClick={props.handleOpenFilterList}
            style={{ color: 'var(--text-primary)' }}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default Filter;
