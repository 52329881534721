/* eslint-disable no-undef */
import axios from 'axios';
import AuthenticationService from './AuthenticationService';

const API_URL = process.env.REACT_APP_API_URL;
class UserService {
  getAllUsers() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getAllEmployees() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/users`, { params: { by_role: 'user' } })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getUserById(id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/users/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/sessions/me`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  getHourRegistrationsOfCurrentUser() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/hour_registrations`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getAllLeaveRequestsOfCurrentUser() {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/api/v1/leave_requests`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  addNewUser(
    firstName,
    lastName,
    email,
    vacation_hours,
    last_working_day,
    slack_account,
    client_id,
    role
  ) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/users`, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          vacation_hours: vacation_hours,
          last_working_day: last_working_day,
          slack_account: slack_account,
          client_id: client_id,
          role: role,
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  }

  editUser(
    id,
    firstName,
    lastName,
    vacation_hours,
    last_working_day,
    slack_account
  ) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/users`, {
          id: id,
          first_name: firstName,
          last_name: lastName,
          vacation_hours: vacation_hours,
          last_working_day: last_working_day,
          slack_account: slack_account,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  deleteUser(id) {
    return new Promise((resolve) => {
      axios
        .delete(`${API_URL}/users/${id}`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  forgottenPassword(email) {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/passwords`, {
          email: email,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve('An unknown error has occured.');
        });
    });
  }

  changePassword(pwd) {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/passwords_update_password`, {
          id: AuthenticationService.getCurrentUser().user_id,
          pwd: pwd,
        })
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  }

  getClientUsers(client_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/client_users`, {
          params: {
            client_id: client_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getClientPerson(client_id) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/client_person`, {
          params: {
            client_id: client_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  /* FILTERS */

  getFilteredUsersStringField(field) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/filter_asc_field`, {
          params: { field: field },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  getFilteredUsersArray(array) {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/filter_asc_array`, {
          params: { array: array },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }
}

export default new UserService();
