/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import '../UserManagement.css';
import { useTranslation } from 'react-i18next';

import UserService from '../../services/UserService';
import CloseButton from '../../components/CloseButton';

function ModalFormDelete(props) {
  const [user, setUser] = useState(Object);
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      setUser(await UserService.getUserById(props.id));
    })();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await UserService.deleteUser(user.id);
    if (response.deleted === false) {
      setValid(false);
      setMessage(response.message);
    } else {
      setValid(true);
      props.setSnackbarMessage(response.message);
      props.handleCloseDelete();
      props.openSnackbar();
    }
  };

  return (
    <Box className="delete-employee-modal">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('users.confirm')}
          <CloseButton
            closeModal={props.handleCloseDelete}
            style={{ marginTop: '-1%' }}
          />
        </h3>
        <p className="confirm-delete-text">
          {t('users.confirm-delete', {
            first_name: user.first_name,
            last_name: user.last_name,
          })}{' '}
        </p>
        {!valid ? <div className="error-message-delete">{message}</div> : null}
        <div className="delete-actions">
          <button
            type="submit"
            className="confirm-delete"
            onClick={handleSubmit}
          >
            {t('users.confirm')}
          </button>
          <button
            type="submit"
            className="discard-delete"
            onClick={props.handleCloseDelete}
          >
            {t('users.discard')}
          </button>
        </div>
      </form>
    </Box>
  );
}

export default ModalFormDelete;
