/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../Dashboard.css';
import MultipleDayVacation from './MultipleDayVacation';
import SingleDayVacation from './SingleDayVacation';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function VacationRegistration(props) {
  const [showSingleDay, setShowSingleDay] = useState(true);

  const { t } = useTranslation('common');

  const [alignment, setAlignment] = useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  function showSingleDayForm() {
    setShowSingleDay(true);
  }

  function showMultipleDaysForm() {
    setShowSingleDay(false);
  }

  return (
    <div className="verlof-registration">
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{
          backgroundColor: 'var(--blue-color)',
          width: '100%',
          borderRadius: '0px',
          textTransform: 'none',
          paddingBottom: '5px',
          paddingTop: '5px',
          color: '#fff',
          borderTop: '.5px solid #fff',
        }}
      >
        <ToggleButton
          value="left"
          aria-label="left aligned"
          size="small"
          sx={{
            marginRight: '15%',
            marginLeft: '15%',
            border: 'none',
            borderRadius: '0px',
            color: '#fff',
            textTransform: 'capitalize',
            fontFamily: 'Lato',
            fontSize: '15px',
            '&.Mui-selected': {
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.20) !important',
            },
          }}
          onClick={showSingleDayForm}
        >
          + {t('leave-requests.single-label')}
        </ToggleButton>
        <ToggleButton
          value="right"
          aria-label="right aligned"
          size="small"
          sx={{
            marginRight: '10%',
            marginLeft: '10%',
            border: 'none',
            borderRadius: '0px',
            textTransform: 'capitalize',
            color: '#fff',
            fontFamily: 'Lato',
            fontSize: '15px',
            '&.Mui-selected': {
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.20) !important',
            },
          }}
          onClick={showMultipleDaysForm}
        >
          + {t('leave-requests.multiple-label')}
        </ToggleButton>
      </ToggleButtonGroup>
      {showSingleDay === true ? (
        <SingleDayVacation
          onSubmitLeaveRequests={props.onSubmitLeaveRequests}
          getPendingLeaveRequests={props.getPendingLeaveRequests}
        />
      ) : (
        <MultipleDayVacation
          onSubmitLeaveRequests={props.onSubmitLeaveRequests}
          getPendingLeaveRequests={props.getPendingLeaveRequests}
        />
      )}
    </div>
  );
}

export default VacationRegistration;
