import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProjectService from '../services/ProjectService';
import './ProjectManagement.css';
import { BsInfoLg, BsPlusLg } from 'react-icons/bs';
import { VscEdit } from 'react-icons/vsc';
import ModalInfo from './Modals/ModalInfo';
import Modal from '@mui/material/Modal';
import ModalEdit from './Modals/ModalEdit';
import ModalAdd from './Modals/ModalAdd';
import ModalAddContributor from './Modals/ModalAddContributor';
import FilterProjects from './Filter/FilterProjects';
import { useTranslation } from 'react-i18next';

export default function ProjectsManagement() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [projects, setProjects] = React.useState([]);

  const { t } = useTranslation('common');

  const getProjects = async () => {
    setProjects(await ProjectService.getAllProjects());
  };

  const [openFilterList, setOpenFilterList] = useState(false);
  const handleOpenFilterList = () => setOpenFilterList(true);
  const handleCloseFilterList = async () => {
    setOpenFilterList(false);
    getProjects();
  };

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleOpenInfo = () => setOpenModalInfo(true);
  const handleCloseInfo = () => {
    setOpenModalInfo(false);
    localStorage.removeItem('id');
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenEdit = () => setOpenModalEdit(true);
  const handleCloseEdit = () => {
    setOpenModalEdit(false);
    localStorage.removeItem('id');
    getProjects();
  };

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const handleOpenAdd = () => setOpenModalAdd(true);
  const handleCloseAdd = () => {
    setOpenModalAdd(false);
    localStorage.removeItem('id');
    getProjects();
  };

  const [openModalAddContributor, setOpenModalAddContributor] = useState(false);
  const handleOpenAddContributor = () => setOpenModalAddContributor(true);
  const handleCloseAddContributor = () => {
    setOpenModalAddContributor(false);
    localStorage.removeItem('id');
    getProjects();
  };

  useEffect(() => {
    getProjects();
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="projects">
      <TableContainer component={Paper} className="projects-management">
        <Table
          aria-label="custom pagination table"
          sx={{ backgroundColor: 'var(--table-bg-color)' }}
        >
          <TableBody>
            <FilterProjects
              projects={projects}
              setProjects={setProjects}
              openFilterList={openFilterList}
              setOpenFilterList={setOpenFilterList}
              handleOpenFilterList={handleOpenFilterList}
              handleCloseFilterList={handleCloseFilterList}
            />
            <TableRow>
              <button
                className="add-project"
                onClick={() => {
                  handleOpenAdd();
                }}
              >
                {t('projects.button-add')}
              </button>
              <Modal
                open={openModalAdd}
                onClose={handleCloseAdd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ModalAdd closeModal={handleCloseAdd} />
              </Modal>
            </TableRow>
            {(rowsPerPage > 0
              ? projects.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : projects
            ).map((p) => (
              <TableRow key={p.id} className="project-container">
                <div className="project-container" key={p.id} id="project-info">
                  <p className="p-client">{p.client.name}</p>
                  <div className="project-actions">
                    <h2 className="p-title">{p.title}</h2>
                    <button
                      className="add-contributor-project"
                      onClick={() => {
                        handleOpenAddContributor();
                        localStorage.setItem('id', p.id);
                      }}
                    >
                      <BsPlusLg />
                    </button>
                    <Modal
                      open={openModalAddContributor}
                      onClose={handleCloseAddContributor}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <ModalAddContributor
                        id={localStorage.getItem('id')}
                        closeModal={handleCloseAddContributor}
                      />
                    </Modal>
                    <button
                      className="view-project-info"
                      onClick={() => {
                        handleOpenInfo();
                        localStorage.setItem('id', p.id);
                      }}
                    >
                      <BsInfoLg className="info-logo" />
                    </button>
                    <Modal
                      open={openModalInfo}
                      onClose={handleCloseInfo}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <ModalInfo
                        id={localStorage.getItem('id')}
                        closeModal={handleCloseInfo}
                      />
                    </Modal>
                    <button
                      className="edit-project-info"
                      onClick={() => {
                        handleOpenEdit();
                        localStorage.setItem('id', p.id);
                      }}
                    >
                      <VscEdit className="edit-logo" />
                    </button>
                    <Modal
                      open={openModalEdit}
                      onClose={handleCloseEdit}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <ModalEdit
                        id={localStorage.getItem('id')}
                        closeModal={handleCloseEdit}
                      />
                    </Modal>
                  </div>
                  <div className="p-hours">
                    <p className="p-allocated">
                      {t('projects.allocated-hours')}: {p.allocated_hours}
                    </p>
                    <p className="p-worked">
                      {t('projects.worked-hours')}: {p.spent_hours}
                    </p>
                    <p className="p-left">
                      {t('projects.left-hours')}: {p.remaining_hours}
                    </p>
                  </div>
                </div>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                  backgroundColor: 'var(--table-bg-color)',
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ color: 'var(--text-primary)' }}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
