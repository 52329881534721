/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import LeaveRequestService from '../../../services/LeaveRequestService';
import Box from '@mui/material/Box';
import CalendarUtils from '../CalendarUtils';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../components/CloseButton';

// `forwardRef` is used here to prevent errors from Material UI Modal due to it requiring a reference,
// but passing it results in an unwanted active border around the component

const Modal = React.forwardRef(function ModalLeaveRequest(props) {
  const [leaveReq, setLeaveReq] = useState(Object);
  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      setLeaveReq(await LeaveRequestService.getLeaveRequestById(props.id));
    })();
  }, [props.id]);

  return (
    <Box className="project-info-box">
      <div className="info-modal-form">
        <h1 className="info-lr-name">
          {t('leave-requests.header-modal')}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '1%' }}
          />
        </h1>
        {leaveReq.end_date !== null ? (
          <>
            <div className="info-p-data">
              <h3>{t('commons.from')}</h3>
              <p>{new Date(leaveReq.start_date).toUTCString().slice(0, 16)}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('commons.until')}</h3>
              <p>{new Date(leaveReq.end_date).toUTCString().slice(0, 16)}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('commons.reason')}</h3>
              <p>{leaveReq.leave_type}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('leave-requests.status')}</h3>
              <p>{leaveReq.status}</p>
            </div>
          </>
        ) : (
          <>
            <div className="info-p-data">
              <h3>{t('commons.date')}</h3>
              <p>{new Date(leaveReq.start_date).toUTCString().slice(0, 16)}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('commons.from')}</h3>
              <p>{CalendarUtils.showMinutes(leaveReq.start_time)}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('commons.until')}</h3>
              <p>{CalendarUtils.showMinutes(leaveReq.end_time)}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('commons.reason')}</h3>
              <p>{leaveReq.leave_type}</p>
            </div>
            <div className="info-p-data">
              <h3>{t('leave-requests.status')}</h3>
              <p>{leaveReq.status}</p>
            </div>
          </>
        )}
      </div>
    </Box>
  );
});

export default Modal;
