/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Filter from './FilterByEmployee';
import nlLocale from '@fullcalendar/core/locales/nl';
import enLocale from '@fullcalendar/core/locales/en-gb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '@mui/material/Modal';
import FilterByProject from './FilterByProject';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HourRegistrationService from '../../services/HourRegistrationService';
import CalendarUtils from '../../Dashboard/Calendar/CalendarUtils';
import AuthenticationService from '../../services/AuthenticationService';
import ModalInfo from '../../Dashboard/Calendar/Modals/ModalInfo';
import '../AdminDashboard.css';
import UserService from '../../services/UserService';
import ProjectService from '../../services/ProjectService';
import FilterUtils from './FilterUtils';
import Page from '../../components/Page';

function CalendarEmployees({ logOut }) {
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleCloseInfo = () => {
    setOpenModalInfo(false);
    localStorage.removeItem('event');
  };

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(false);

  const [events, setEvents] = useState<any[]>([]);
  const [hoursWeek, setHoursWeek] = useState(0);
  const [hoursMonth, setHoursMonth] = useState(0);

  const [users, setUsers] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);

  const [calendarDates, setCalendarDates] = useState({
    calendarMonth: new Date().getMonth(),
    calendarYear: new Date().getFullYear(),
    startDate: new Date(),
    endDate: new Date(),
  });

  const { t } = useTranslation('common');

  const buttonText = {
    today: t('calendar.today'),
    month: t('calendar.month'),
    week: t('calendar.week'),
    day: t('calendar.day'),
  };

  function getLocale() {
    return localStorage.getItem('lang') === 'en' ? enLocale : nlLocale;
  }

  useEffect(() => {
    try {
      const checkIfAdmin: boolean | undefined =
        AuthenticationService.checkIfAdmin();
      setIsAdmin(checkIfAdmin);
    } catch (error) {
      console.log('Error: ' + error.message);
    }
    void (async () => {
      const hourReg = await HourRegistrationService.getHourRegistrations();
      setEvents(await CalendarUtils.showEventsOnEmployeeCalendar(hourReg));
      localStorage.removeItem('employee');
      localStorage.removeItem('project');
      const updateHours = async () => {
        const all = await HourRegistrationService.getHoursOfCompanyFilter(
          calendarDates.startDate,
          calendarDates.endDate
        );
        setHoursWeek(all.hours_week);
        setHoursMonth(all.hours_month);
      };
      updateHours();
      const users = await UserService.getAllUsers();
      setUsers(users);
      const projects = await ProjectService.getAllProjects();
      setProjects(projects);
    })();
  }, [t, logOut, navigate, calendarDates.startDate, calendarDates.endDate]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = async (startDate: Date, endDate: Date, type: string) => {
    const data = await HourRegistrationService.exportCSV(
      startDate,
      endDate,
      type
    );

    downloadFile({
      data: data,
      fileName: `hour-registrations-${startDate.toLocaleString('default', {
        month: 'long',
      })}-${startDate.getFullYear()}.csv`,
      fileType: 'text/csv',
    });
  };

  const handleFilterEmployeeInputChange = async (event) => {
    setEvents([]);
    if (event.target.value !== 'all') {
      localStorage.setItem('employee', event.target.value);
      if ('project' in localStorage) {
        const project_id = localStorage.getItem('project');
        FilterUtils.filterEmployeesProjects(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          event.target.value,
          project_id,
          calendarDates.startDate,
          calendarDates.endDate
        );
      } else {
        FilterUtils.filterEmployees(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          event.target.value,
          calendarDates.startDate,
          calendarDates.endDate
        );
      }
    } else {
      localStorage.removeItem('employee');
      if ('project' in localStorage) {
        const project_id = localStorage.getItem('project');
        FilterUtils.filterProjects(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          project_id,
          calendarDates.startDate,
          calendarDates.endDate
        );
      } else {
        FilterUtils.getCompanyStatistics(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          calendarDates.startDate,
          calendarDates.endDate
        );
      }
    }
  };

  const handleFilterProjectInputChange = async (event) => {
    setEvents([]);
    if (event.target.value !== 'all') {
      localStorage.setItem('project', event.target.value);
      if ('employee' in localStorage) {
        const employee_id = localStorage.getItem('employee');
        FilterUtils.filterEmployeesProjects(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          employee_id,
          event.target.value,
          calendarDates.startDate,
          calendarDates.endDate
        );
      } else {
        FilterUtils.filterProjects(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          event.target.value,
          calendarDates.startDate,
          calendarDates.endDate
        );
      }
    } else {
      localStorage.removeItem('project');
      if ('employee' in localStorage) {
        const employee_id = localStorage.getItem('employee');
        FilterUtils.filterEmployees(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          employee_id,
          calendarDates.startDate,
          calendarDates.endDate
        );
      } else {
        FilterUtils.getCompanyStatistics(
          setEvents,
          setHoursWeek,
          setHoursMonth,
          calendarDates.startDate,
          calendarDates.endDate
        );
      }
    }
  };

  return (
    <Page>
      <div className="admin-dashboard">
        {isAdmin ? (
          <>
            <div className="hours-overview-calendar">
              <h3 className="hours-overview">{t('calendar.hours-overview')}</h3>
              {calendarDates.calendarMonth === new Date().getMonth() &&
                calendarDates.calendarYear === new Date().getFullYear() && (
                  <h3 className="totaal-week">
                    {t('dashboard.worked-hours-label')} {hoursWeek}
                  </h3>
                )}
              {calendarDates.calendarMonth === new Date().getMonth() &&
              calendarDates.calendarYear === new Date().getFullYear() ? (
                <h3 className="verlofsaldo">
                  {t('dashboard.worked-hours-month-label')} {hoursMonth}
                </h3>
              ) : (
                <h3 className="verlofsaldo">
                  Worked in&nbsp;
                  {CalendarUtils.getMonthName(
                    calendarDates.calendarMonth
                  )}: {hoursMonth}
                </h3>
              )}
            </div>
            <div className="calendar-employees">
              <div className="cal">
                <div className="full-calendar-style">
                  <FullCalendar
                    headerToolbar={{
                      left: 'prev,next',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    showNonCurrentDates={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    aspectRatio={2.7}
                    buttonText={buttonText}
                    locale={getLocale()}
                    events={events}
                    eventBorderColor={'var(--blue-color)'}
                    eventDisplay={'auto'}
                    eventClick={function (arg) {
                      const groupId = arg.event._def.groupId;
                      if (groupId === '1') {
                        localStorage.setItem('event', arg.event.id);
                        setOpenModalInfo(true);
                      }
                    }}
                    eventContent={function (arg) {
                      const groupId = arg.event._def.groupId;
                      if (groupId === '1') {
                        return (
                          <span className="event-calendar">
                            {arg.event.title}
                          </span>
                        );
                      }
                    }}
                    datesSet={(dateInfo) => {
                      setCalendarDates((prevState) => ({
                        ...prevState,
                        calendarMonth: dateInfo.start.getMonth(),
                        calendarYear: dateInfo.start.getFullYear(),
                        startDate: dateInfo.start,
                        endDate: dateInfo.end,
                      }));
                    }}
                  />
                  {'event' in localStorage ? (
                    <Modal open={openModalInfo} onClose={handleCloseInfo}>
                      <ModalInfo
                        id={localStorage.getItem('event')}
                        closeModal={handleCloseInfo}
                        isAdmin={true}
                      />
                    </Modal>
                  ) : null}
                </div>
              </div>
              <div className="filter-calendar">
                <Filter
                  users={users}
                  handleFilterInputChange={handleFilterEmployeeInputChange}
                />
                <FilterByProject
                  projects={projects}
                  handleFilterInputChange={handleFilterProjectInputChange}
                />
                <button
                  className="export-hours-button"
                  onClick={() => {
                    exportToCsv(
                      calendarDates.startDate,
                      calendarDates.endDate,
                      'calendar'
                    );
                  }}
                >
                  {t('commons.export-showed-csv', {
                    month: new Date(
                      0,
                      calendarDates.calendarMonth
                    ).toLocaleString('default', { month: 'long' }),
                  })}
                </button>
                <button
                  className="export-hours-button"
                  onClick={() => {
                    exportToCsv(
                      calendarDates.startDate,
                      calendarDates.endDate,
                      'all'
                    );
                  }}
                >
                  {t('commons.export-all-csv')}
                </button>
              </div>
            </div>
          </>
        ) : (
          <p className="not-logged-in">{t('commons.not-allowed')}</p>
        )}
      </div>
    </Page>
  );
}

export default CalendarEmployees;
