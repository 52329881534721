class CalendarUtils {
  convertSecondsToString(seconds) {
    return new Date(parseInt(seconds) * 1000).toISOString().slice(11, 16);
  }

  convertHourStringToSeconds(time) {
    const arr = time.split(':');
    const seconds = arr[0] * 3600 + arr[1] * 60;
    return seconds;
  }

  showMinutes(value) {
    const hours = Math.floor(value / 3600);
    const min = Math.floor((value - hours * 3600) / 60);
    return `${hours.toString().padStart(2, '0')}:${min
      .toString()
      .padStart(2, '0')}`;
  }

  addDayToDate(date) {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result.toISOString().slice(0, 10);
  }

  getMonthName(monthNumber) {
    switch (monthNumber) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
      default:
        return '';
    }
  }

  async showEventsOnCalendar(hr, lr, lrPending) {
    let eventsToShow = [];
    await Promise.all(
      hr.map(async (e) =>
        eventsToShow.push({
          groupId: 1,
          title: e.project.jira_name,
          id: e.id,
          start: `${e.date} ${this.convertSecondsToString(e.from)}`,
          end: `${e.date} ${this.convertSecondsToString(e.till)}`,
          eventDisplay: 'auto',
          backgroundColor: 'var(--blue-color)',
          allDay: false,
        })
      )
    );

    await Promise.all(
      lr?.map(async (e) =>
        e.end_time
          ? eventsToShow.push({
              groupId: 2,
              id: e.id,
              title: 'Vacation',
              start: `${e.start_date} ${this.convertSecondsToString(
                e.start_time
              )}`,
              end: `${e.start_date} ${this.convertSecondsToString(e.end_time)}`,
              allDay: false,
              displayEventTime: false,
              displayEventEnd: false,
              backgroundColor: '#ED2877',
            })
          : eventsToShow.push({
              groupId: 3,
              id: e.id,
              title: 'Vacation',
              start: `${e.start_date} 00:00`,
              end: `${this.addDayToDate(e.end_date)} 00:00`,
              backgroundColor: '#ED2877',
              classNames: ['multiple-calendar'],
            })
      )
    );

    await Promise.all(
      lrPending?.map(async (e) =>
        e.end_time
          ? eventsToShow.push({
              groupId: 4,
              id: e.id,
              title: 'Pending',
              start: `${e.start_date} ${this.convertSecondsToString(
                e.start_time
              )}`,
              end: `${e.start_date} ${this.convertSecondsToString(e.end_time)}`,
              allDay: false,
              displayEventTime: false,
              displayEventEnd: false,
              classNames: ['pending-single-day'],
            })
          : eventsToShow.push({
              groupId: 5,
              id: e.id,
              title: 'Pending',
              start: `${e.start_date} 00:00`,
              end: `${this.addDayToDate(e.end_date)} 00:00`,
              classNames: ['pending-multiple-day'],
            })
      )
    );

    return eventsToShow;
  }

  async showEventsOnEmployeeCalendar(hr) {
    let eventsToShow = [];
    await Promise.all(
      hr?.map(async (e) =>
        eventsToShow.push({
          groupId: 1,
          title: `${e.project.jira_name}: ${e.user.first_name}`,
          id: e.id,
          start: `${e.date} ${this.convertSecondsToString(e.from)}`,
          end: `${e.date} ${this.convertSecondsToString(e.till)}`,
          eventDisplay: 'auto',
          backgroundColor: 'var(--blue-color)',
          allDay: false,
        })
      )
    );
    return eventsToShow;
  }
}

export default new CalendarUtils();
