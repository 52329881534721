import React, { useState } from 'react';
import InputGroup from '../../components/InputGroup';
import { useTranslation } from 'react-i18next';
import AuthenticationService from '../../services/AuthenticationService';
import UserService from '../../services/UserService';
import Paper from '@mui/material/Paper';

function AccountDetails() {
  const user = AuthenticationService.getCurrentUser();
  const [values, setValues] = useState({
    firstName: user.first_name,
    lastName: user.last_name,
    password: '',
    confirmPassword: '',
  });

  const { t } = useTranslation('common');

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handlePasswordInputChange = (event) => {
    setValues((prev) => ({ ...prev, password: event.target.value }));
  };

  const handleConfirmPasswordInputChange = (event) => {
    setValues((prev) => ({ ...prev, confirmPassword: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        setValid(false);
        setMessage('The passwords do not match.');
      } else {
        setValid(true);
        setMessage(await UserService.changePassword(values.password));
      }
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Paper
      elevation={5}
      sx={{ width: '150%', backgroundColor: 'var(--table-bg-color)' }}
      className="form-container-password"
    >
      <h2 className="account-details">{t('profile-page.account-details')}</h2>
      <InputGroup
        label={t('clients.name')}
        value={`${values.firstName} ${values.lastName}`}
        name="hr-date"
        type="text"
        disabled={true}
        placeholder={`${values.firstName} ${values.lastName}`}
        id={'pp-name'}
      />
      <form onSubmit={handleSubmit}>
        <h4 className="change-pwd-header">{t('profile-page.change-pwd')}</h4>
        {submitted && valid ? (
          <div className="hr-success">{message}</div>
        ) : null}
        {submitted && !valid ? <div className="hr-error">{message}</div> : null}
        <InputGroup
          id="pp-pwd"
          label={t('profile-page.password')}
          onChange={handlePasswordInputChange}
          value={values.password}
          name="pp-pwd"
          type="password"
        />
        <InputGroup
          id="pp-confirm-pwd"
          label={t('profile-page.confirm-password')}
          onChange={handleConfirmPasswordInputChange}
          value={values.confirmPassword}
          name="pp-confirm-pwd"
          type="password"
        />
        <button className="change-password">{t('commons.button-text')}</button>
      </form>
    </Paper>
  );
}

export default AccountDetails;
