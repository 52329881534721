/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Navbar.css';

function Navbar(props: {
  logOut: () => void;
  isAdmin: boolean;
  pendingLeaveRequests: number;
  getPendingLeaveRequests: () => void;
  isClient: boolean;
}) {
  const [t] = useTranslation('common');
  const navigate = useNavigate();

  function handleLogOut() {
    props.logOut();
    navigate('/');
  }

  return (
    <div>
      <div>
        <nav className="navbar">
          <div className="navbar-container container">
            {'token' in localStorage ? (
              <ul className="nav-menu">
                <li className="nav-item">
                  <Link to="/home" className="navbar-logo">
                    <h1 className="logo-text">SIMDO</h1>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/home" className="nav-links">
                    {t('navbar.home')}
                  </Link>
                </li>
                {props.isAdmin ? (
                  <li className="nav-item">
                    {props.pendingLeaveRequests > 0 ? (
                      <Link to="/admin-dashboard" className="nav-links">
                        {t('navbar.admin-dashboard')}
                        {'  '}
                        <p className="pending-lr-notification">
                          {props.pendingLeaveRequests}
                        </p>
                      </Link>
                    ) : (
                      <Link to="/admin-dashboard" className="nav-links">
                        {t('navbar.admin-dashboard')}
                      </Link>
                    )}
                  </li>
                ) : null}
                {props.isAdmin ? (
                  <li className="nav-item">
                    <Link to="/employees-hours-overiew" className="nav-links">
                      {t('navbar.employees-hours-overview')}
                    </Link>
                  </li>
                ) : null}
                <li className="nav-item">
                  <Link to="/leaves-overview" className="nav-links">
                    {t('navbar.leaves-overview')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile-page" className="nav-links">
                    {t('navbar.profile')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/"
                    className="nav-links"
                    id="logout-button"
                    onClick={handleLogOut}
                  >
                    {t('navbar.log-out')}
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="nav-menu">
                <li className="nav-item">
                  <Link to="/" className="navbar-logo">
                    <h1 className="logo-text">SIMDO</h1>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
