import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import Navbar from './Navbar/Navbar';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import AuthenticationService from './services/AuthenticationService';
import ProfilePage from './ProfilePage/ProfilePage';
import LeaveRequestService from './services/LeaveRequestService';
import AdminDashboard from './AdminDashboard/AdminDashboard';
import LeaveRequestManagement from './LeaveRequestManagement/LeaveRequestManagement';
import CalendarEmployees from './AdminDashboard/CalendarEmployees/CalendarEmployees';
import ClientDashboard from './ClientDashboard/ClientDashboard';

import './utils/api.ts';

import './App.css';
import './DarkMode.css';

function App() {
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);

  function handleLogOut() {
    AuthenticationService.logOut();
  }

  async function getPendingLeaveRequests() {
    setPendingLeaveRequests(
      await LeaveRequestService.getLeaveRequestsByStatus('pending')
    );
  }

  useEffect(() => {
    void (async () => {
      setPendingLeaveRequests(
        await LeaveRequestService.getLeaveRequestsByStatus('pending')
      );
    })();
  }, []);

  return (
    <Router>
      <Navbar
        logOut={handleLogOut}
        isAdmin={AuthenticationService.checkIfAdmin() ?? false}
        pendingLeaveRequests={pendingLeaveRequests.length}
        getPendingLeaveRequests={getPendingLeaveRequests}
        isClient={AuthenticationService.checkIfClient() ?? false}
      />
      <Routes>
        <Route
          path="/home"
          element={
            <Dashboard
              logOut={handleLogOut}
              pendingLeaveRequests={pendingLeaveRequests}
              getPendingLeaveRequests={getPendingLeaveRequests}
            />
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/admin-dashboard"
          element={
            <AdminDashboard
              logOut={handleLogOut}
              pendingLeaveRequests={pendingLeaveRequests}
              getPendingLeaveRequests={getPendingLeaveRequests}
            />
          }
        />
        <Route
          path="/employees-hours-overiew"
          element={<CalendarEmployees logOut={handleLogOut} />}
        />
        <Route
          path="/leaves-overview"
          element={<LeaveRequestManagement logOut={handleLogOut} />}
        />
        <Route
          path="/profile-page"
          element={<ProfilePage logOut={handleLogOut} />}
        />
        <Route
          path="/clients-overview"
          element={<ClientDashboard logOut={handleLogOut} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
