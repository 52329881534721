/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/UserService';
import Paper from '@mui/material/Paper';

function Statistics({ first_name }) {
  const { t } = useTranslation('common');
  const [user, setUser] = useState();

  useEffect(() => {
    void (async () => {
      const user = await UserService.getCurrentUser();
      setUser(user);
    })();
  }, []);

  return (
    <Paper className="statistics-container" elevation={3}>
      <h2 className="greeting-header">
        {t('profile-page.hello')}, {first_name}
      </h2>
      <div className="pp-statistics">
        <div className="pp-stat">
          <label className="stat-label">{t('profile-page.worked-week')}</label>
          <p className="statistics-hours-week">{user?.worked_hours}</p>
        </div>
        <div className="pp-stat">
          <label className="stat-label">
            {t('profile-page.used-vacation-days')}
          </label>
          <p className="statistics-used-vacation">
            {Math.round(user?.used_vacation_days / 8)}
          </p>
        </div>
        <div className="pp-stat">
          <label className="stat-label">
            {t('profile-page.left-vacation-days')}
          </label>
          <p className="statistics-left-vacation">
            {Math.round(user?.vacation_hours / 8)}
          </p>
        </div>
      </div>
    </Paper>
  );
}

export default Statistics;
