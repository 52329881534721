/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ProjectService from '../../services/ProjectService';
import TextAreaGroup from '../../components/TextAreaGroup';
import InputGroup from '../../components/InputGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalEdit(props) {
  const [project, setProject] = useState(Object);

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleDescriptionInputChange = (event) => {
    setProject({ ...project, description: event.target.value });
  };

  const handleJiraLinkInputChange = (event) => {
    setProject({ ...project, jira_link: event.target.value });
  };

  const handleGitHubLinkInputChange = (event) => {
    setProject({ ...project, repo_link: event.target.value });
  };

  const handleJiraNameInputChange = (event) => {
    setProject({ ...project, jira_name: event.target.value });
  };

  useEffect(() => {
    void (async () => {
      setProject(await ProjectService.getProjectById(props.id, setProject));
    })();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      project.description &&
      project.jira_link &&
      project.repo_link &&
      project.jira_name
    ) {
      setValid(true);
      setMessage(
        await ProjectService.updateProject(
          props.id,
          project.description,
          project.jira_link,
          project.repo_link,
          project.jira_name
        )
      );
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="project-info-box">
      <form className="info-modal-form" onSubmit={handleSubmit}>
        <h1 className="info-p-name">
          {project.title}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '1%' }}
          />
        </h1>
        {submitted && valid ? (
          <div className="success-message-edit-project">{message}</div>
        ) : null}
        {submitted && !valid ? (
          <div className="error-message-edit-project">{message}</div>
        ) : null}
        <TextAreaGroup
          id="edit-project-description"
          label={t('projects.description')}
          onChange={handleDescriptionInputChange}
          value={project.description || ''}
          name="edit-project-description"
          placeholder="Enter the description"
        />
        <InputGroup
          id="edit-project-jira"
          label={t('projects.jira-link')}
          onChange={handleJiraLinkInputChange}
          value={project.jira_link || ''}
          name="edit-project-jira"
          type="text"
          placeholder="Enter the Jira link"
        />
        <InputGroup
          id="edit-project-repo"
          label={t('projects.repo-link')}
          onChange={handleGitHubLinkInputChange}
          value={project.repo_link || ''}
          name="edit-project-repo"
          type="text"
          placeholder="Enter the GitHub link"
        />
        <InputGroup
          id="edit-project-jira-key"
          label={t('projects.jira-key-name')}
          onChange={handleJiraNameInputChange}
          value={project.jira_name}
          name="edit-project-jira-key"
          type="text"
          placeholder="Enter the Jira key name"
        />
        <SubmitButton text={t('projects.button-edit')} />
      </form>
    </Box>
  );
}

export default ModalEdit;
