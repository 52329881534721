/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import InputGroup from '../../../components/InputGroup';
import SelectGroup from '../../../components/SelectGroup';
import ProjectService from '../../../services/ProjectService';
import SubmitButton from '../../../components/SubmitButton';
import CalendarUtils from '../CalendarUtils';
import HourRegistrationService from '../../../services/HourRegistrationService';
import CloseButton from '../../../components/CloseButton';

function ModalEdit(props) {
  const [hourReg, setHourReg] = useState(Object);
  const { t } = useTranslation('common');

  const [project, setProject] = useState(Object);

  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function formatTickets(tickets) {
    const stringTickets = tickets?.split(', ') || [];
    let ticketsArray = [];
    for (let i = 0; i < stringTickets.length; i++) {
      ticketsArray.push({ value: i, label: stringTickets[i] });
    }
    return ticketsArray;
  }

  useEffect(() => {
    void (async () => {
      setHourReg(props.hourReg);
      if (props.hourReg.tickets !== '') {
        setSelectValue(formatTickets(props.hourReg.tickets));
      }
      const projects = await ProjectService.getAllProjects();
      setProjects(projects);
      setProject(props.hourReg.project);
      const ticketsProject = await ProjectService.getJiraTickets(
        props.hourReg.project.id
      );
      setTickets(ticketsProject);
    })();
  }, [props.hourReg, project.id]);

  function formatProjects(projects) {
    let options = [];
    for (const p of projects) {
      options.push({ value: p.id, label: p.title });
    }
    return options;
  }

  function formatArrayToString(allTickets) {
    let ticketsString = '';
    for (const t of allTickets) {
      ticketsString += t.label + ', ';
    }
    ticketsString = ticketsString.slice(0, -2);
    return ticketsString;
  }

  const handleDateInputChange = (event) => {
    setHourReg((prev) => ({ ...prev, date: event.target.value }));
  };

  const handleFromInputChange = (event) => {
    setHourReg((prev) => ({
      ...prev,
      from: CalendarUtils.convertHourStringToSeconds(event.target.value),
    }));
  };

  const handleTillInputChange = (event) => {
    setHourReg((prev) => ({
      ...prev,
      till: CalendarUtils.convertHourStringToSeconds(event.target.value),
    }));
  };

  const handleTicketInputChange = (event) => {
    setHourReg((prev) => ({ ...prev, tickets: event }));
    setSelectValue(event);
    setIsLoading(false);
  };

  const handleProjectInputChange = async (event) => {
    setSelectValue('');
    setIsLoading(true);
    setProject((prev) => ({ ...prev, id: event.value }));
    setProject((prev) => ({ ...prev, title: event.label }));
    setTickets([]);
    setTickets(await ProjectService.getJiraTickets(event.value));
  };

  const handleCommentInputChange = (event) => {
    setHourReg((prev) => ({ ...prev, comment: event.target.value }));
  };

  const closeModals = () => {
    props.closeThisModal();
    props.closeMainModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (hourReg.date && hourReg.from && hourReg.till && hourReg.project) {
      if (
        hourReg.tickets?.length > 0 ||
        hourReg.comment ||
        (hourReg.tickets?.length > 0 && hourReg.comment)
      ) {
        const message = await HourRegistrationService.updateHourRegistration(
          hourReg.id,
          hourReg.date,
          hourReg.from,
          hourReg.till,
          project.id,
          formatArrayToString(selectValue),
          hourReg.comment
        );
        props.onSubmit();
        props.getUserWorkedHours();
        closeModals();
        props.setSnackbarMessage(message);
        props.setSnackbarSeverity('success');
      } else {
        props.setSnackbarMessage('Please enter all the details!');
        props.setSnackbarSeverity('error');
      }
    }
    props.openSnackbar();
  };

  return (
    <Box className="project-info-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('hour-registration-form.header-edit')}
          <CloseButton
            closeModal={props.closeThisModal}
            style={{ marginTop: '-1%' }}
          />
        </h3>
        <InputGroup
          id="hr-date"
          label={t('commons.date')}
          onChange={handleDateInputChange}
          value={hourReg.date}
          name="hr-date"
          type="date"
        />
        <InputGroup
          id="hr-from"
          label={t('commons.from')}
          onChange={handleFromInputChange}
          value={CalendarUtils.showMinutes(hourReg.from)}
          name="hr-from"
          type="time"
        />
        <InputGroup
          id="hr-till"
          label={t('commons.until')}
          onChange={handleTillInputChange}
          value={CalendarUtils.showMinutes(hourReg.till)}
          name="hr-till"
          type="time"
        />
        <SelectGroup
          id="hr-projects"
          isMulti={false}
          name="hr-projects"
          onChange={handleProjectInputChange}
          value={{ value: project.id, label: project.title }}
          label={t('hour-registration-form.project')}
          options={formatProjects(projects)}
          placeholder={project.title}
        />
        <SelectGroup
          id="hr-tickets"
          isMulti={true}
          name="hr-tickets"
          onChange={handleTicketInputChange}
          value={selectValue}
          label={t('hour-registration-form.tickets')}
          options={tickets}
          isLoading={isLoading}
        />
        <InputGroup
          id="hr-comment"
          label={t('hour-registration-form.comment')}
          onChange={handleCommentInputChange}
          value={hourReg.comment}
          name="hr-comment"
          type="text"
        />
        <SubmitButton text={t('commons.button-text')} />
      </form>
    </Box>
  );
}

export default ModalEdit;
