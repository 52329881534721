/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';
import Calendar from './Calendar/Calendar';
import './Dashboard.css';
import RegistrationMenu from './RegistrationMenu';
import UserService from '../services/UserService';
import HourRegistrationService from '../services/HourRegistrationService';
import LeaveRequestService from '../services/LeaveRequestService';
import { useTranslation } from 'react-i18next';
import SnackbarComponent from '../components/Snackbar';
import ClientDashboard from '../ClientDashboard/ClientDashboard';
import Page from '../components/Page';

function Dashboard(props) {
  const navigate = useNavigate();

  const [hoursReg, setHoursReg] = useState([]);
  const [acceptedLeaveRequests, setAcceptedLeaveRequests] = useState([]);
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);
  const [workedHours, setWorkedHours] = useState(0);
  const [vacation_hours, setVacationHours] = useState(0);
  const user_id = AuthenticationService.getCurrentUser()?.user_id;
  const { t } = useTranslation('common');
  const isClient = AuthenticationService.checkIfClient();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => setOpenSnackbar(true);
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const getUserHours = async () => {
    setHoursReg(await UserService.getHourRegistrationsOfCurrentUser());
  };

  const getUserRequests = async () => {
    setPendingLeaveRequests(
      await LeaveRequestService.getLeaveRequestsByStatus('pending', true)
    );
  };

  const getUserWorkedHours = async () => {
    setWorkedHours(await HourRegistrationService.getHoursWeekOfUser(user_id));
  };

  useEffect(() => {
    void (async () => {
      getUserHours();
      getUserWorkedHours();
      setAcceptedLeaveRequests(
        await LeaveRequestService.getLeaveRequestsByStatus('accepted', true)
      );
      setPendingLeaveRequests(
        await LeaveRequestService.getLeaveRequestsByStatus('pending', true)
      );
      const user = await UserService.getCurrentUser();
      setVacationHours(user.vacation_hours);
    })();
  }, [workedHours, navigate, props.logOut]);

  return (
    <Page>
      <div className="dashboard-user">
        {!isClient ? (
          <>
            <div className="hours-overview-dash">
              <h3 className="totaal-week">
                {t('dashboard.worked-hours-label')} {workedHours}
              </h3>
              <h3 className="verlofsaldo">
                {t('dashboard.vacation-days-label')} {vacation_hours / 8}
              </h3>
            </div>
            <div className="calendar-dash">
              <div className="shifts-calendar">
                <Calendar
                  hoursReg={hoursReg}
                  length={hoursReg.length}
                  acceptedLeaveRequests={acceptedLeaveRequests}
                  pendingLeaveRequests={pendingLeaveRequests}
                  getUserRequests={getUserRequests}
                  onSubmit={getUserHours}
                  getUserWorkedHours={getUserWorkedHours}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarSeverity={setSnackbarSeverity}
                  openSnackbar={handleOpenSnackbar}
                />
              </div>
              <div className="dash-registration-menu">
                <RegistrationMenu
                  onSubmit={getUserHours}
                  hoursReg={hoursReg}
                  getUserWorkedHours={getUserWorkedHours}
                  onSubmitLeaveRequests={getUserRequests}
                  getPendingLeaveRequests={props.getPendingLeaveRequests}
                />
              </div>
            </div>
          </>
        ) : (
          <ClientDashboard />
        )}
        <SnackbarComponent
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </div>
    </Page>
  );
}

export default Dashboard;
