/* eslint-disable react/prop-types */
import React from 'react';

function TextAreaGroup(props) {
  return (
    <div className="field">
      <label className="label-element" htmlFor={props.id}>
        {props.label}{' '}
      </label>
      <textarea
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        className="textarea-element"
        placeholder={props.placeholder}
        rows="4"
        cols="20"
      />
    </div>
  );
}

export default TextAreaGroup;
