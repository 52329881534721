/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ProjectService from '../../services/ProjectService';
import ClientService from '../../services/ClientService';
import InputGroup from '../../components/InputGroup';
import SelectGroup from '../../components/SelectGroup';
import TextAreaGroup from '../../components/TextAreaGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalAdd({ closeModal }) {
  const [clients, setClients] = useState([]);

  const [values, setValues] = useState({
    title: '',
    client: 1,
    description: '',
    jira_link: '',
    repo_link: '',
    left_hours: 0,
    jira_name: '',
  });
  const { t } = useTranslation('common');

  function formatClients(clients) {
    let options = [];
    for (const c of clients) {
      options.push({ value: c.id, label: c.name });
    }
    return options;
  }

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleTitleInputChange = (event) => {
    setValues({ ...values, title: event.target.value });
  };

  const handleClientInputChange = (event) => {
    setValues((prev) => ({ ...prev, client: event }));
  };

  const handleDescriptionInputChange = (event) => {
    setValues({ ...values, description: event.target.value });
  };

  const handleJiraLinkInputChange = (event) => {
    setValues({ ...values, jira_link: event.target.value });
  };

  const handleRepoLinkInputChange = (event) => {
    setValues({ ...values, repo_link: event.target.value });
  };

  const handleLeftHoursInputChange = (event) => {
    setValues({ ...values, left_hours: event.target.value });
  };

  const handleJiraNameInputChange = (event) => {
    setValues({ ...values, jira_name: event.target.value });
  };

  useEffect(() => {
    void (async () => {
      setClients(await ClientService.getAllClients());
    })();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.title &&
      values.client.value &&
      values.description &&
      values.jira_link &&
      values.repo_link &&
      values.left_hours &&
      values.jira_name
    ) {
      setValid(true);
      setMessage(
        await ProjectService.addNewProject(
          values.title,
          values.client.value,
          values.description,
          values.jira_link,
          values.repo_link,
          values.left_hours,
          values.jira_name
        )
      );
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-project">
          {t('projects.header-add')}{' '}
          <CloseButton closeModal={closeModal} style={{ marginTop: '-2%' }} />
        </h3>
        {submitted && valid ? (
          <div className="success-message-add-project">{message}</div>
        ) : null}
        {submitted && !valid ? (
          <div className="error-message-add-project">{message}</div>
        ) : null}
        <InputGroup
          id="add-project-title"
          label={t('projects.title')}
          onChange={handleTitleInputChange}
          value={values.title}
          name="add-project-title"
          type="text"
          placeholder="Enter the title"
        />
        <TextAreaGroup
          id="add-project-description"
          label={t('projects.description')}
          onChange={handleDescriptionInputChange}
          value={values.description}
          name="add-project-description"
          placeholder="Enter the description"
        />
        <InputGroup
          id="add-project-jira"
          label={t('projects.jira-link')}
          onChange={handleJiraLinkInputChange}
          value={values.jira_link}
          name="add-project-jira"
          type="text"
          placeholder="Enter the Jira link"
        />
        <InputGroup
          id="add-project-repo"
          label={t('projects.repo-link')}
          onChange={handleRepoLinkInputChange}
          value={values.repo_link}
          name="add-project-repo"
          type="text"
          placeholder="Enter the GitHub link"
        />
        <InputGroup
          id="add-project-jira-key"
          label={t('projects.jira-key-name')}
          onChange={handleJiraNameInputChange}
          value={values.jira_name}
          name="add-project-jira-key"
          type="text"
          placeholder="Enter the Jira key name"
        />
        <InputGroup
          id="add-project-hours"
          label={t('projects.hours-to-develop')}
          onChange={handleLeftHoursInputChange}
          value={values.left_hours}
          name="add-project-hours"
          type="text"
          placeholder={0}
        />
        <SelectGroup
          id="add-project-client"
          isMulti={false}
          name="add-project-client"
          onChange={handleClientInputChange}
          value={values.client}
          label={t('projects.client')}
          options={formatClients(clients)}
        />
        <SubmitButton text={t('projects.button-add')} />
      </form>
    </Box>
  );
}

export default ModalAdd;
