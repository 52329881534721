/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import LeaveRequestService from '../../services/LeaveRequestService';
import InputGroup from '../../components/InputGroup';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';

function SingleDayVacation(props) {
  const [values, setValues] = useState({
    start_date: new Date().toISOString().slice(0, 10),
    start_time: '09:00',
    end_time: '18:00',
    type: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleStartDateInputChange = (event) => {
    setValues({ ...values, start_date: event.target.value });
  };

  const handleStartTimeInputChange = (event) => {
    setValues({ ...values, start_time: event.target.value });
  };

  const handleEndTimeInputChange = (event) => {
    setValues({ ...values, end_time: event.target.value });
  };

  const handleTypeInputChange = (event) => {
    setValues({ ...values, type: event.target.value });
  };

  function convertHourStringToSeconds(time) {
    const arr = time.split(':');
    const seconds = arr[0] * 3600 + arr[1] * 60;
    return seconds;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.start_date &&
      values.start_time &&
      values.end_time &&
      values.type
    ) {
      setValid(true);
      setMessage(
        await LeaveRequestService.addLeaveRequest(
          values.start_date,
          null,
          values.type,
          convertHourStringToSeconds(values.start_time),
          convertHourStringToSeconds(values.end_time),
          values.type
        )
      );
      props.onSubmitLeaveRequests();
      props.getPendingLeaveRequests();
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };
  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h3 className="new-hr">{t('leave-requests.header-single')}</h3>
      {submitted && valid ? <div className="hr-success">{message}</div> : null}
      {submitted && !valid ? <div className="hr-error">{message}</div> : null}
      <InputGroup
        id="lr-start-date"
        label={t('commons.date')}
        onChange={handleStartDateInputChange}
        value={values.start_date}
        name="lr-start-date"
        type="date"
      />
      <InputGroup
        id="lr-start-time"
        label={t('commons.from')}
        onChange={handleStartTimeInputChange}
        value={values.start_time}
        name="lr-start-time"
        type="time"
      />
      <InputGroup
        id="lr-end-time"
        label={t('commons.until')}
        onChange={handleEndTimeInputChange}
        value={values.end_time}
        name="lr-end-time"
        type="time"
      />
      <InputGroup
        id="lr-reason"
        label={t('commons.reason')}
        onChange={handleTypeInputChange}
        value={values.type}
        name="lr-reason"
        type="text"
        placeholder="Reason"
      />
      <SubmitButton text={t('commons.button-text')} />
    </form>
  );
}

export default SingleDayVacation;
