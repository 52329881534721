import Switch from '@mui/material/Switch';
import '../DarkMode.css';
import { useTranslation } from 'react-i18next';

const setDark = () => {
  localStorage.setItem('theme', 'dark');

  document.documentElement.setAttribute('data-theme', 'dark');
};

const setLight = () => {
  localStorage.setItem('theme', 'light');
  document.documentElement.setAttribute('data-theme', 'light');
};

const storedTheme = localStorage.getItem('theme');

const prefersDark =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultDark =
  storedTheme === 'dark' || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();
}

const toggleTheme = (e) => {
  if (e.target.checked) {
    setDark();
  } else {
    setLight();
  }
};

function DarkMode() {
  const { t } = useTranslation('common');
  return (
    <>
      <label
        className="label-element"
        htmlFor="checkbox"
        style={{ marginLeft: '5%', fontSize: '17px', width: '130px' }}
        id="dark-mode-label"
      >
        {t('profile-page.dark-mode')}
      </label>
      <Switch
        defaultChecked={defaultDark}
        onChange={toggleTheme}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </>
  );
}

export default DarkMode;
