/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import UserService from '../../services/UserService';
import HourRegistrationService from '../../services/HourRegistrationService';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalFormInfo(props) {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [workedHours, setWorkedHours] = useState(0);
  const [vacationDays, setVacationDays] = useState(0);
  const [projects, setProjects] = useState([]);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const user = await UserService.getUserById(props.id);
      setLeaveRequests(user.leave_requests);
      setWorkedHours(await HourRegistrationService.getHoursWeekOfUser(user.id));
      setVacationDays(user.vacation_hours / 8);
      setProjects(user.projects);
    })();
  }, [props.id]);

  function getProjects() {
    let projectString = 'None';
    if (projects.length > 0) {
      projectString = '';
      projects?.map((p) => (projectString += `${p.title}, `));
      projectString = projectString.slice(0, -2);
    }
    return projectString;
  }

  return (
    <Box className="project-info-box">
      <div className="modal-form">
        <h1 className="info-p-name">
          {t('users.header-overview')}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '1%' }}
          />
        </h1>
        <div className="info-p-data">
          <h3>{t('users.projects-contributing-to')}:</h3>
          <p>{getProjects()}</p>
        </div>
        <div className="info-p-data">
          <h3>{t('users.worked-week')}:</h3>
          <p>{workedHours}</p>
        </div>
        <div className="info-p-data">
          <h3>{t('users.submitted-leave-requests')}:</h3>
          <p>{leaveRequests.length}</p>
        </div>
        <div className="info-p-data">
          <h3>{t('users.left-vacation-days')}:</h3>
          <p>{vacationDays}</p>
        </div>
      </div>
    </Box>
  );
}

export default ModalFormInfo;
