/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import eng from '../img/eng.png';
import nl from '../img/nl.png';
import SelectGroup from './SelectGroup';

function LanguageChoice() {
  const [t, i18n] = useTranslation('common');

  const options = [
    {
      value: 'en',
      label: (
        <div className="language-option">
          <img
            src={eng}
            className="nav-links"
            id="en-logo"
            alt="English choice"
          ></img>
          <p>English</p>
        </div>
      ),
    },
    {
      value: 'nl',
      label: (
        <div className="language-option">
          <img
            src={nl}
            className="nav-links"
            id="nl-logo"
            alt="Dutch choice"
          ></img>
          <p>Nederlands</p>
        </div>
      ),
    },
  ];

  const defaultValue =
    localStorage.getItem('lang') === 'en' ? options[0] : options[1];

  const handleLanguageInputChange = async (event) => {
    const lang = event.value;
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <div className="language-choice">
      {' '}
      <label
        className="label-element"
        htmlFor="img"
        style={{ marginLeft: '5%', fontSize: '17px' }}
        id="language-choice-label"
      >
        {t('profile-page.language')}
      </label>
      <SelectGroup
        options={options}
        onChange={handleLanguageInputChange}
        defaultValue={defaultValue}
        id="language-choice-select"
      />
    </div>
  );
}

export default LanguageChoice;
