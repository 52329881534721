import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals.ts';

import common_en from './translations/en/common.json';
import common_nl from './translations/nl/common.json';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('lang') || 'en', // default language
  resources: {
    en: {
      common: common_en,
    },
    nl: {
      common: common_nl,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
