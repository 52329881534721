/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CalendarUtils from '../CalendarUtils';
import InputGroup from '../../../components/InputGroup';
import LeaveRequestService from '../../../services/LeaveRequestService';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../components/CloseButton';

// `forwardRef` is used here to prevent errors from Material UI Modal due to it requiring a reference,
// but passing it results in an unwanted active border around the component

const Modal = React.forwardRef(function ModalPendingLeaveRequest(props) {
  const [leaveReq, setLeaveReq] = useState(Object);

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [isSingle, setIsSingle] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const leaveReq = await LeaveRequestService.getLeaveRequestById(props.id);
      setLeaveReq(leaveReq);
      setIsSingle(leaveReq.end_date === null);
    })();
  }, []);

  const handleStartDateInputChange = (event) => {
    setLeaveReq({ ...leaveReq, start_date: event.target.value });
  };

  const handleEndDateInputChange = (event) => {
    setLeaveReq({ ...leaveReq, end_date: event.target.value });
  };

  const handleTypeInputChange = (event) => {
    setLeaveReq({ ...leaveReq, leave_type: event.target.value });
  };

  const handleStartTimeInputChange = (event) => {
    setLeaveReq({
      ...leaveReq,
      start_time: CalendarUtils.convertHourStringToSeconds(event.target.value),
    });
  };

  const handleEndTimeInputChange = (event) => {
    setLeaveReq({
      ...leaveReq,
      end_time: CalendarUtils.convertHourStringToSeconds(event.target.value),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSingle) {
      if (
        leaveReq.start_date &&
        leaveReq.start_time &&
        leaveReq.end_time &&
        leaveReq.leave_type
      ) {
        setValid(true);
        setMessage(
          await LeaveRequestService.updatePendingLeaveRequest(
            leaveReq.id,
            leaveReq.start_date,
            null,
            leaveReq.leave_type,
            leaveReq.start_time,
            leaveReq.end_time
          )
        );
        props.getUserRequests();
      } else {
        setMessage('Please enter all the details!');
      }
    } else {
      if (leaveReq.start_date && leaveReq.end_date && leaveReq.leave_type) {
        setValid(true);
        setMessage(
          await LeaveRequestService.updatePendingLeaveRequest(
            leaveReq.id,
            leaveReq.start_date,
            leaveReq.end_date,
            leaveReq.leave_type,
            null,
            null
          )
        );
        props.getUserRequests();
      } else {
        setMessage('Please enter all the details!');
      }
    }

    setSubmitted(true);
  };

  return (
    <div>
      {' '}
      <Box className="project-info-box">
        <div className="info-modal-form">
          <h1 className="info-plr-name">
            {t('leave-requests.header-modal-pending')}
            <CloseButton
              closeModal={props.closeModal}
              style={{ marginTop: '1%' }}
            />
          </h1>
          {submitted && valid ? (
            <div className="hr-success">{message}</div>
          ) : null}
          {submitted && !valid ? (
            <div className="hr-error">{message}</div>
          ) : null}
          {isSingle === false ? (
            <form className="update-leave-request" onSubmit={handleSubmit}>
              <InputGroup
                id="lrp-from"
                label={t('commons.from')}
                onChange={handleStartDateInputChange}
                value={leaveReq.start_date}
                name="lrp-from"
                type="date"
              />
              <InputGroup
                id="lrp-until"
                label={t('commons.until')}
                onChange={handleEndDateInputChange}
                value={leaveReq.end_date}
                name="lrp-until"
                type="date"
              />
              <InputGroup
                id="lrp-reason"
                label={t('commons.reason')}
                onChange={handleTypeInputChange}
                value={leaveReq.leave_type}
                name="lrp-reason"
                type="text"
              />
              <button type="submit" className="update-pending-request">
                Save changes
              </button>
            </form>
          ) : (
            <form className="update-leave-request" onSubmit={handleSubmit}>
              <InputGroup
                id="hr-date"
                label={t('commons.date')}
                onChange={handleStartDateInputChange}
                value={leaveReq.start_date}
                name="hr-date"
                type="date"
              />
              <InputGroup
                id="lrp-from"
                label={t('commons.from')}
                onChange={handleStartTimeInputChange}
                value={CalendarUtils.showMinutes(leaveReq.start_time)}
                name="lrp-from"
                type="time"
              />
              <InputGroup
                id="lrp-until"
                label={t('commons.until')}
                onChange={handleEndTimeInputChange}
                value={CalendarUtils.showMinutes(leaveReq.end_time)}
                name="lrp-until"
                type="time"
              />
              <InputGroup
                id="lrp-reason"
                label={t('commons.reason')}
                onChange={handleTypeInputChange}
                value={leaveReq.leave_type}
                name="lrp-reason"
                type="text"
              />
              <button type="submit" className="update-pending-request">
                {t('commons.button-text')}
              </button>
            </form>
          )}
        </div>
      </Box>
    </div>
  );
});

export default Modal;
