/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import AuthenticationService from '../services/AuthenticationService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Page({ children }) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const errorMessage = t('commons.not-logged-in');

  useEffect(() => {
    void AuthenticationService.checkTokenExp(navigate);
  }, [navigate]);

  return (
    <>
      {'token' in localStorage ? (
        <div>{children}</div>
      ) : (
        <div className="admin">
          <p className="not-logged-in">{errorMessage}</p>
        </div>
      )}
    </>
  );
}

export default Page;
