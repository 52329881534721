/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import HourRegistrationService from '../../../services/HourRegistrationService';
import CalendarUtils from '../CalendarUtils';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../components/SubmitButton';
import Modal from '@mui/material/Modal';
import ModalDelete from './ModalDelete';
import ModalEdit from './ModalEdit';
import CloseButton from '../../../components/CloseButton';

// `forwardRef` is used here to prevent errors from Material UI Modal due to it requiring a reference,
// but passing it results in an unwanted active border around the component

const ModalInfo = React.forwardRef(function ModalInfo(props) {
  const [hourReg, setHourReg] = useState();
  const isAdmin = props.isAdmin;
  const { t } = useTranslation('common');

  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const handleCloseConfirmDelete = () => {
    setOpenModalConfirmDelete(false);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleCloseEdit = () => {
    setOpenModalEdit(false);
  };

  const style = {
    marginTop: '-30px',
    marginBottom: '30px',
  };

  const onClickDelete = () => {
    setOpenModalConfirmDelete(true);
  };

  const onClickEdit = () => {
    setOpenModalEdit(true);
  };

  useEffect(() => {
    void (async () => {
      const hourReg = await HourRegistrationService.getHourRegistrationById(
        props.id
      );
      setHourReg(hourReg);
    })();
  }, [props.id]);

  if (!hourReg) return null;

  return (
    <Box className="project-info-box">
      <div className="info-modal-form">
        <h1 className="info-p-name">
          {t('modal-hour-reg-info.header')} {hourReg.date}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '1%' }}
          />
        </h1>
        <div className="info-p-data">
          <h3>{t('modal-hour-reg-info.project-name')}</h3>
          <p>{hourReg.project.title}</p>
        </div>
        {isAdmin ? (
          <div className="info-p-data">
            <h3>Employee</h3>
            <p>
              {hourReg.user.first_name} {hourReg.user.last_name}
            </p>
          </div>
        ) : null}
        <div className="info-p-data">
          <h3>{t('modal-hour-reg-info.comment')}</h3>
          {hourReg.comment !== '' ? (
            <p>{hourReg.comment}</p>
          ) : (
            <p>There is no comment</p>
          )}
        </div>
        <div className="info-p-data">
          <h3>{t('modal-hour-reg-info.tickets')}</h3>
          {hourReg.tickets !== '' ? (
            <p>{hourReg.tickets}</p>
          ) : (
            <p>There are no tickets</p>
          )}
        </div>
        <div className="info-p-data">
          <h3>{t('commons.from')}</h3>
          <p>{CalendarUtils.showMinutes(hourReg.from)}</p>
        </div>
        <div className="info-p-data">
          <h3>{t('commons.until')}</h3>
          <p>{CalendarUtils.showMinutes(hourReg.till)}</p>
        </div>
        {!isAdmin ? (
          <>
            <SubmitButton
              text={t('hour-registration-form.button-edit')}
              style={style}
              onClick={onClickEdit}
            />
            <Modal
              open={openModalEdit}
              onClose={handleCloseEdit}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              useNativeDriver={true}
            >
              <ModalEdit
                hourReg={hourReg}
                closeThisModal={handleCloseEdit}
                closeMainModal={props.closeModal}
                onSubmit={props.onSubmit}
                getUserWorkedHours={props.getUserWorkedHours}
                setSnackbarMessage={props.setSnackbarMessage}
                setSnackbarSeverity={props.setSnackbarSeverity}
                openSnackbar={props.openSnackbar}
              />
            </Modal>
            <SubmitButton
              text={t('hour-registration-form.button-delete')}
              style={style}
              onClick={onClickDelete}
            />
            <Modal
              open={openModalConfirmDelete}
              onClose={handleCloseConfirmDelete}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              useNativeDriver={true}
            >
              <ModalDelete
                hourReg={hourReg}
                closeThisModal={handleCloseConfirmDelete}
                closeMainModal={props.closeModal}
                onSubmit={props.onSubmit}
                setSnackbarMessage={props.setSnackbarMessage}
                setSnackbarSeverity={props.setSnackbarSeverity}
                openSnackbar={props.openSnackbar}
                getUserWorkedHours={props.getUserWorkedHours}
              />
            </Modal>
          </>
        ) : null}
      </div>
    </Box>
  );
});

export default ModalInfo;
