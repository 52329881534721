/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ProjectService from '../../services/ProjectService';
import '../ProjectManagement.css';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalInfo(props) {
  const [project, setProject] = useState(Object);
  const [contributors, setContributors] = useState([]);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      setProject(await ProjectService.getProjectById(props.id));
      setContributors(await ProjectService.getUsersOfProject(props.id));
    })();
  }, []);

  function getContributorsOfProject() {
    let contributorsString = 'None assigned';
    if (contributors.length > 0) {
      contributorsString = '';
      contributors?.map(
        (c) => (contributorsString += `${c.first_name} ${c.last_name}` + ', ')
      );
      contributorsString = contributorsString.slice(0, -2);
    }
    return contributorsString;
  }

  return (
    <Box className="project-info-box">
      <div className="info-modal-form">
        <h1 className="info-p-name">
          {project.title}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '1%' }}
          />
        </h1>
        <div className="info-p-data">
          <h3>{t('projects.description')}</h3>
          <p>{project.description}</p>
        </div>
        <div className="info-p-data">
          <h3>{t('projects.jira-link')}:</h3>
          <a href={project.jira_link}>{project.jira_link}</a>
        </div>
        <div className="info-p-data">
          <h3>{t('projects.repo-link')}:</h3>
          <a href={project.repo_link}>{project.repo_link}</a>
        </div>
        <div className="info-p-data">
          <h3>{t('projects.contributors')}:</h3>
          <div className="info-p-contributors">
            <p>{getContributorsOfProject()}</p>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ModalInfo;
