/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import LeaveRequestService from '../services/LeaveRequestService';
import TableFooter from '@mui/material/TableFooter';
import CalendarUtils from '../Dashboard/Calendar/CalendarUtils';
import './LeaveRequestManagement.css';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';
import Filter from './Filter';
import Page from '../components/Page';
import InputGroup from '../components/InputGroup';

function LeaveRequestManagement({ logOut }) {
  //check here the logOut props
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [order, setOrder] = useState('asc');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { t, i18n } = useTranslation('common');

  const headerStyling = {
    color: 'var(--text-primary)',
    fontWeight: '550',
    fontFamily: 'Lato',
    backgroundColor: 'var(--table-bg-color',
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leaveRequests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateInputChange = async (event) => {
    setStartDate(event.target.value);
    setLeaveRequests(
      await LeaveRequestService.getFilteredLeaveRequestsByStartDate(
        event.target.value
      )
    );
  };

  const compareLeaveRequests = (a, b, order) => {
    const dateA = new Date(a.start_date);
    const dateB = new Date(b.start_date);

    if (order === 'asc') {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };

  const sortLeaveRequests = (order) => {
    const sortedLeaveRequests = [...leaveRequests].sort((a, b) => {
      return compareLeaveRequests(a, b, order);
    });
    setLeaveRequests(sortedLeaveRequests);
  };

  const createSortHandler = () => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    setOrder(newOrder);
    sortLeaveRequests(newOrder);
  };

  const clearFilter = async () => {
    setLeaveRequests(
      await LeaveRequestService.getLeaveRequestsStatus('accepted')
    );
    setStartDate(new Date());
  };

  useEffect(() => {
    void (async () => {
      setLeaveRequests(
        await LeaveRequestService.getLeaveRequestsStatus('accepted')
      );
    })();
  }, []);

  return (
    <Page>
      <div className="leave-requests-overview">
        <div className="pending-leave-tables">
          <div className="blue-header-leaves-manag">
            <p>{t('leave-requests.accepted-leave-requests')}</p>
            <Filter setLeaveRequests={setLeaveRequests} />
            <div
              className="sort-by-date"
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '15%',
              }}
            >
              <p style={{ width: '80%' }}>{t('leave-requests.start-date')}</p>
              <InputGroup
                id="hr-date"
                onChange={handleDateInputChange}
                value={startDate}
                name="hr-date"
                type="date"
                placeholder="Start date"
                style={{
                  marginTop: '3%',
                  backgroundColor: 'var(--blue-color)',
                  border: '.3px solid #fff',
                  color: '#fff',
                }}
              />
              <button
                className="sort-start-date"
                style={{
                  padding: '5px 10px',
                  fontSize: '12px',
                  fontFamily: 'Lato',
                  border: '1px solid #fff',
                  width: '90%',
                  borderRadius: '5px',
                  color: '#fff',
                  fontWeight: '600',
                  height: '25px',
                  minHeight: '35',
                  backgroundColor: 'var(--blue-color)',
                  marginTop: '3%',
                }}
                onClick={clearFilter}
              >
                {t('leave-requests.clear-filter')}
              </button>
            </div>
          </div>
          {leaveRequests ? (
            <TableContainer
              component={Paper}
              className="table-container"
              sx={{
                borderRadius: '0px',
                backgroundColor: 'var(--table-bg-color)',
                color: 'var(--text-primary)',
                border: '.5px solid var(--table-boder-color)',
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyling}>
                      {t('leave-requests.person')}
                    </TableCell>
                    <TableCell style={headerStyling}>
                      <TableSortLabel
                        active
                        direction={order}
                        onClick={createSortHandler}
                        style={{ color: 'var(--text-primary)' }}
                        sx={{
                          '& .MuiTableSortLabel-icon': {
                            color: 'var(--text-primary) !important',
                          },
                        }}
                      >
                        {t('leave-requests.period')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={headerStyling}>
                      {t('commons.reason')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ color: 'var(--text-primary)' }}>
                  {(rowsPerPage > 0
                    ? leaveRequests.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : leaveRequests
                  ).map((leaveRequest) => (
                    <TableRow
                      key={leaveRequest.id}
                      className="leave-requests-row"
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        color: 'var(--text-primary)',
                      }}
                    >
                      <TableCell className="table-cell-lr-management">
                        {leaveRequest.user.first_name}{' '}
                        {leaveRequest.user.last_name}
                      </TableCell>
                      {leaveRequest.end_date !== null ? (
                        <TableCell className="table-cell-lr-management">
                          {new Date(leaveRequest.start_date)
                            .toUTCString()
                            .slice(0, 16)}{' '}
                          -{' '}
                          {new Date(leaveRequest.end_date)
                            .toUTCString()
                            .slice(0, 16)}
                        </TableCell>
                      ) : (
                        <TableCell className=" table-cell-lr-management">
                          {new Date(leaveRequest.start_date)
                            .toUTCString()
                            .slice(0, 16)}
                          , {CalendarUtils.showMinutes(leaveRequest.start_time)}{' '}
                          - {CalendarUtils.showMinutes(leaveRequest.end_time)}
                        </TableCell>
                      )}
                      <TableCell className=" table-cell-lr-management">
                        {leaveRequest.leave_type}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                        backgroundColor: 'var(--table-bg-color)',
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={leaveRequests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      backgroundColor: '#3474E68',
                      color: 'var(--text-primary)',
                    }}
                  />
                </TableRow>
              </TableFooter>
            </TableContainer>
          ) : (
            <div className="no-leave-requests">
              <p>{t('leave-requests.no-leave-requests')}</p>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}
export default LeaveRequestManagement;
