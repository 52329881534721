/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LeaveRequestService from '../services/LeaveRequestService';
import CalendarUtils from '../Dashboard/Calendar/CalendarUtils';
import { Box, TablePagination } from '@mui/material';
import SnackbarComponent from '../components/Snackbar';
import { useTranslation } from 'react-i18next';

function PendingLeaveRequests(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const handleOpenSnackbarSuccess = () => setOpenSnackbarSuccess(true);
  const handleCloseSnackbarSuccess = () => setOpenSnackbarSuccess(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  const { t } = useTranslation('common');

  const headerStyling = {
    color: '#fff',
    fontWeight: '550',
    fontFamily: 'Lato',
    backgroundColor: 'var(--blue-color)',
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - props.pendingLeaveRequests.length
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function acceptLeave(id) {
    const response = await LeaveRequestService.updateStatus(id, 1);
    setSnackbarMessage(response.message);
    handleOpenSnackbarSuccess();
    if (response.updated === true) {
      setSnackbarSeverity('success');
    } else {
      setSnackbarSeverity('error');
    }
    props.getPendingLeaveRequests();
  }

  async function declineLeave(id) {
    const response = await LeaveRequestService.updateStatus(id, 2);
    setSnackbarMessage(response.message);
    handleOpenSnackbarSuccess();
    if (response.updated === true) {
      setSnackbarSeverity('success');
    } else {
      setSnackbarSeverity('error');
    }
    props.getPendingLeaveRequests();
  }

  return (
    <div className="pending-leave-tables">
      <SnackbarComponent
        open={openSnackbarSuccess}
        handleClose={handleCloseSnackbarSuccess}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Box sx={{ width: '100%' }}>
        <Paper elevation={5} sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={headerStyling}>
                    {t('leave-requests.person')}
                  </TableCell>
                  <TableCell style={headerStyling}>
                    {t('leave-requests.period')}
                  </TableCell>
                  <TableCell style={headerStyling}>
                    {t('commons.reason')}
                  </TableCell>
                  <TableCell style={headerStyling}>
                    {t('leave-requests.status')}
                  </TableCell>
                  <TableCell style={headerStyling}>
                    {t('leave-requests.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.pendingLeaveRequests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((leaveRequest) => {
                    return (
                      <TableRow
                        style={{ fontSize: '12px' }}
                        key={leaveRequest.id}
                        className="leave-requests-row"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          backgroundColor: 'var(--table-bg-color)',
                          color: 'var(--text-primary)',
                        }}
                      >
                        <TableCell className="table-cell-pr">
                          {leaveRequest.user.first_name}{' '}
                          {leaveRequest.user.last_name}
                        </TableCell>
                        {leaveRequest.end_date !== null ? (
                          <TableCell className="table-cell-pr">
                            {new Date(leaveRequest.start_date)
                              .toUTCString()
                              .slice(0, 16)}{' '}
                            -{' '}
                            {new Date(leaveRequest.end_date)
                              .toUTCString()
                              .slice(0, 16)}
                          </TableCell>
                        ) : (
                          <TableCell className="table-cell-pr">
                            {new Date(leaveRequest.start_date)
                              .toUTCString()
                              .slice(0, 16)}
                            ,{' '}
                            {CalendarUtils.showMinutes(leaveRequest.start_time)}{' '}
                            - {CalendarUtils.showMinutes(leaveRequest.end_time)}
                          </TableCell>
                        )}
                        <TableCell className="table-cell-pr">
                          {leaveRequest.leave_type}
                        </TableCell>
                        <TableCell className="table-cell-pr">
                          <p className="leave-status-p">
                            {leaveRequest.status}
                          </p>
                        </TableCell>
                        {leaveRequest.status === 'pending' ? (
                          <TableCell className="leave-actions">
                            <button
                              className="accept-leave"
                              type="submit"
                              onClick={() => acceptLeave(leaveRequest.id)}
                            >
                              {t('leave-requests.accept-button')}
                            </button>
                            <button
                              className="decline-leave"
                              type="submit"
                              onClick={() => declineLeave(leaveRequest.id)}
                            >
                              {t('leave-requests.decline-button')}
                            </button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                      backgroundColor: 'var(--table-bg-color)',
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={props.pendingLeaveRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              backgroundColor: 'var(--table-bg-color)',
              color: 'var(--text-primary)',
            }}
          />
        </Paper>
      </Box>
    </div>
  );
}

export default PendingLeaveRequests;
