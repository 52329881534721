/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import UserService from '../services/UserService';
import LeaveRequestsUser from './Components/LeaveRequestsUser';
import './ProfilePage.css';
import Statistics from './Components/Statistics';
import RecentProjects from './Components/RecentProjects';
import AccountDetails from './Components/AccountDetails';
import StatisticsClient from './Components/StatisticsClient';
import Page from '../components/Page';
import Preferences from './Components/Preferences';

function ProfilePage(props) {
  const [user, setUser] = useState(Object);
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      const user = await UserService.getCurrentUser();
      setUser(user);
    })();
  }, [navigate, props.logOut]);

  return (
    <Page>
      <div className="profile-page">
        <Paper className="pp-container-first" elevation={1}>
          <div className="pp-greeting-container">
            {user.role !== 'client' ? (
              <Statistics first_name={user.first_name} />
            ) : (
              <StatisticsClient
                first_name={user.first_name}
                client_id={user.client_id}
              />
            )}
            <div className="pp-preferrences-container">
              <Preferences />
            </div>
          </div>
          <div className="pp-account-details-container">
            <AccountDetails />
          </div>
        </Paper>
        {user.role !== 'client' && (
          <Paper className="pp-container-second" elevation={3}>
            <div className="pp-leave-requests-container">
              <LeaveRequestsUser />
            </div>
            <div className="pp-recent-projects-container">
              <RecentProjects />
            </div>
          </Paper>
        )}
      </div>
    </Page>
  );
}

export default ProfilePage;
