/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import InputGroup from '../../components/InputGroup';
import LeaveRequestService from '../../services/LeaveRequestService';
import SubmitButton from '../../components/SubmitButton';
import { useTranslation } from 'react-i18next';

function MultipleDayVacation(props) {
  const [values, setValues] = useState({
    start_date: new Date().toISOString().slice(0, 10),
    end_date: new Date().toISOString().slice(0, 10),
    type: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleStartDateInputChange = (event) => {
    setValues({ ...values, start_date: event.target.value });
  };

  const handleEndDateInputChange = (event) => {
    setValues({ ...values, end_date: event.target.value });
  };

  const handleTypeInputChange = (event) => {
    setValues({ ...values, type: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.start_date && values.end_date && values.type) {
      setValid(true);
      setMessage(
        await LeaveRequestService.addLeaveRequest(
          values.start_date,
          values.end_date,
          values.type,
          null,
          null
        )
      );
      props.onSubmitLeaveRequests();
      props.getPendingLeaveRequests();
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };
  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h3 className="new-hr">{t('leave-requests.header-multiple')}</h3>
      {submitted && valid ? <div className="hr-success">{message}</div> : null}
      {submitted && !valid ? <div className="hr-error">{message}</div> : null}
      <InputGroup
        id="lr-start-date"
        label={t('commons.from')}
        onChange={handleStartDateInputChange}
        value={values.start_date}
        name="lr-start-date"
        type="date"
      />
      <InputGroup
        id="lr-end-date"
        label={t('commons.until')}
        onChange={handleEndDateInputChange}
        value={values.end_date}
        name="lr-end-time"
        type="date"
      />
      <InputGroup
        id="lr-reason"
        label={t('commons.reason')}
        onChange={handleTypeInputChange}
        value={values.type}
        name="lr-reason"
        type="text"
        placeholder="Reason"
      />
      <SubmitButton text={t('commons.button-text')} />
    </form>
  );
}

export default MultipleDayVacation;
