/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import HourRegistrationService from '../../../services/HourRegistrationService';
import CloseButton from '../../../components/CloseButton';

function ModalDelete({
  hourReg,
  closeThisModal,
  closeMainModal,
  setSnackbarSeverity,
  setSnackbarMessage,
  openSnackbar,
  onSubmit,
  getUserWorkedHours,
}) {
  const { t } = useTranslation('common');
  const [project, setProject] = useState(Object);

  useEffect(() => {
    void (async () => {
      setProject(hourReg.project);
    })();
  }, [hourReg]);

  const closeModals = () => {
    closeThisModal();
    closeMainModal();
  };

  const handleSubmit = async () => {
    const response = await HourRegistrationService.deleteHourRegistration(
      hourReg.id
    );
    if (response.deleted === true) {
      setSnackbarSeverity('success');
    } else {
      setSnackbarSeverity('error');
    }
    closeModals();
    setSnackbarMessage(response.message);
    openSnackbar();
    onSubmit();
    getUserWorkedHours();
  };

  return (
    <Box className="project-info-box">
      <div className="modal-form">
        <h3 className="new-employee">
          {t('users.confirm')}{' '}
          <CloseButton
            closeModal={closeThisModal}
            style={{ marginTop: '-.5%' }}
          />
        </h3>
        <p className="confirm-delete-text">
          {t('hour-registration-form.confirm-delete', {
            project_name: project.title,
            date: hourReg.date,
          })}{' '}
        </p>
        <div className="delete-actions">
          <button
            type="submit"
            className="confirm-delete"
            onClick={handleSubmit}
          >
            {t('users.confirm')}
          </button>
          <button className="discard-delete" onClick={closeModals}>
            {t('users.discard')}
          </button>
        </div>
      </div>
    </Box>
  );
}

export default ModalDelete;
