/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { VscEdit, VscTrash } from 'react-icons/vsc';
import './ClientManagement.css';
import { Modal } from '@mui/material';
import ModalEdit from './Modals/ModalEdit';
import ModalDelete from './Modals/ModalDelete';
import { useTranslation } from 'react-i18next';
import ModalAddPerson from './Modals/ModalAddPerson';
import UserService from '../services/UserService';
import { BsPlusLg } from 'react-icons/bs';

function RowClient(props) {
  const [open, setOpen] = React.useState(false);
  const [clientPerson, setClientPerson] = useState([]);

  const { t } = useTranslation('common');

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenEdit = () => setOpenModalEdit(true);
  const handleCloseEdit = () => {
    setOpenModalEdit(false);
  };

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const handleOpenDelete = () => setOpenModalDelete(true);
  const handleCloseDelete = () => {
    setOpenModalDelete(false);
  };

  const [openModalAddPerson, setOpenModalAddPerson] = useState(false);
  const handleOpenAddPerson = () => setOpenModalAddPerson(true);
  const handleCloseAddPerson = () => {
    setOpenModalAddPerson(false);
  };

  function getProjectsOfClient() {
    let projects = '';
    props.projects?.map((p) => (projects += p.title + ', '));
    projects = projects.slice(0, -2);
    return projects;
  }

  function getClientPerson() {
    let person = '';
    clientPerson?.map(
      (p) => (person += p.first_name + ' ' + p.last_name + ', ')
    );
    person = person.slice(0, -2);
    return person;
  }

  useEffect(() => {
    void (async () => {
      setClientPerson(await UserService.getClientPerson(props.id));
    })();
  }, [props.id]);

  return (
    <TableRow
      key={props.id}
      className="client-container"
      sx={{
        backgroundColor: 'var(--table-bg-color)',
        color: 'var(--text-primary)',
      }}
    >
      <div key={props.id} id="client-info">
        <div className="client-actions">
          <h2 className="c-title">{props.name}</h2>
          <IconButton
            aria-label="expand row"
            size="small"
            className="see-client-projects"
            onClick={() => setOpen(!open)}
            style={{ color: 'var(--text-primary) !important' }}
          >
            {open ? (
              <KeyboardArrowUpIcon className="client-up" />
            ) : (
              <KeyboardArrowDownIcon className="client-down" />
            )}
          </IconButton>
          <button className="edit-client-info" onClick={handleOpenEdit}>
            <VscEdit />
          </button>
          <Modal
            open={openModalEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalEdit
              id={props.id}
              onSubmit={props.onSubmit}
              closeModal={handleCloseEdit}
            />
          </Modal>
          {props.length === 0 ? (
            <>
              <button className="delete-client" onClick={handleOpenDelete}>
                <VscTrash />
              </button>
              <Modal
                open={openModalDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ModalDelete
                  client={props.client}
                  onSubmit={props.onSubmit}
                  closeModal={handleCloseDelete}
                />
              </Modal>
            </>
          ) : null}
          <button
            className="add-client-contact"
            onClick={() => {
              handleOpenAddPerson();
            }}
          >
            <BsPlusLg />
          </button>
          <Modal
            open={openModalAddPerson}
            onClose={handleCloseAddPerson}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalAddPerson
              client_id={props.id}
              closeModal={handleCloseAddPerson}
            />
          </Modal>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="info-c-data">
            <h4>{t('clients.projects')}:</h4>
            {props.projects?.length > 0 ? (
              <div className="info-p-contributors">
                <p>{getProjectsOfClient()}</p>
              </div>
            ) : (
              <div className="info-p-contributors">
                <p>None</p>
              </div>
            )}
            <h4>{t('clients.contact-person')}:</h4>
            <div className="info-p-contributors">
              {clientPerson.length > 0 ? (
                <p>{getClientPerson()}</p>
              ) : (
                <p>None</p>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </TableRow>
  );
}

export default RowClient;
