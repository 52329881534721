import React, { useState, useEffect, useCallback } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ProjectService from '../../services/ProjectService';
import HourRegistrationService from '../../services/HourRegistrationService';
import { useTranslation } from 'react-i18next';
import AuthenticationService from '../../services/AuthenticationService';

function RecentProjects() {
  const [recentProjects, setRecentProjects] = useState([]);
  const [workedHours, setWorkedHours] = useState([]);

  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const rp = await ProjectService.getRecentProjectsOfUser();
      setRecentProjects(rp);
    })();
  }, []);

  useEffect(() => {
    void (async () => {
      let wh = [];
      if (recentProjects) {
        for (const p of recentProjects) {
          const hours = await HourRegistrationService.getWorkedHoursOnProject(
            AuthenticationService.getCurrentUser().user_id,
            p.id
          );
          wh.push([p.id, hours]);
        }
        setWorkedHours(wh);
      }
    })();
  }, [recentProjects]);

  const getWorkedHoursOfProject = useCallback(
    (project_id) => {
      if (workedHours.length > 0) {
        const hours = workedHours.find((element) => element[0] === project_id);
        return hours[1];
      }
    },
    [workedHours]
  );

  return (
    <Paper elevation={5} className="recent-projects">
      <h2 className="recent-p-header">{t('profile-page.recent-projects')}</h2>
      {recentProjects.length > 0 ? (
        <TableContainer component={Paper} className="recent-projects-table">
          {recentProjects?.sort().map((p) => (
            <div className="project-container" key={p.id}>
              <p className="p-client">{p.client?.name}</p>
              <div className="project-actions">
                <h3 className="p-title-recent">{p.title}</h3>
              </div>
              <div className="p-hours">
                <p className="p-worked">
                  {t('profile-page.hours-registered')}:{' '}
                  {getWorkedHoursOfProject(p.id)}
                </p>
              </div>
            </div>
          ))}
        </TableContainer>
      ) : (
        <p className="no-recent-projects">
          {t('profile-page.no-recent-projects')}
        </p>
      )}
    </Paper>
  );
}

export default RecentProjects;
