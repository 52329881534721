/* eslint-disable react/prop-types */
import React from 'react';
import UserService from '../../services/UserService';
import Filter from '../../components/Filter';
import options from './FilterOptions';

function FilterUsers(props) {
  const handleFilterInputChange = async (event) => {
    let filtered = [];
    props.setUsers([]);
    if (event.type !== 'array') {
      filtered = await UserService.getFilteredUsersStringField(event.field);
      props.setUsers(filtered);
    } else {
      filtered = await UserService.getFilteredUsersArray(event.field);
      props.setUsers(filtered);
    }
  };

  return (
    <Filter
      openFilterList={props.openFilterList}
      handleCloseFilterList={props.handleCloseFilterList}
      handleOpenFilterList={props.handleOpenFilterList}
      options={options}
      handleFilterInputChange={handleFilterInputChange}
    />
  );
}
export default FilterUsers;
