/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import LeaveRequestService from '../services/LeaveRequestService';
import UserService from '../services/UserService';
import './LeaveRequestManagement.css';
import { useTranslation } from 'react-i18next';

function Filter({ setLeaveRequests }) {
  const [users, setUsers] = useState([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      const users = await UserService.getAllUsers();
      setUsers(users);
    })();
  }, []);

  const handleFilterInputChange = async (event) => {
    let filtered = [];
    setLeaveRequests([]);
    if (event.target.value) {
      filtered =
        await LeaveRequestService.getLeaveRequestsByStatusOfCurrentUser(
          event.target.value,
          'accepted'
        );
      setLeaveRequests(filtered);
    } else {
      let all = await LeaveRequestService.getLeaveRequestsStatus('accepted');
      setLeaveRequests(all);
    }
  };

  return (
    <div className="filter-by-employee">
      <select
        onChange={handleFilterInputChange}
        className="select-filter"
        aria-label="Filter"
      >
        <option value="">{t('commons.filter-by-employees')}</option>
        {users.map((user) => (
          <option value={user.id} key={user.id}>
            {user.first_name} {user.last_name}
          </option>
        ))}
      </select>
      <span className="focus"></span>
    </div>
  );
}

export default Filter;
