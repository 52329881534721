/* eslint-disable react/prop-types */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

function CloseButton({ closeModal, style }) {
  return (
    <button className="close-modal-button" onClick={closeModal} style={style}>
      {' '}
      <CloseIcon />
    </button>
  );
}

export default CloseButton;
