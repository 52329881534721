/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HourRegistrationService from '../../services/HourRegistrationService';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import Paper from '@mui/material/Paper';

function StatisticsClient({ first_name, client_id }) {
  const { t } = useTranslation('common');
  const [workedHours, setWorkedHours] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalContributors, setTotalContributors] = useState(0);

  useEffect(() => {
    void (async () => {
      const data = await HourRegistrationService.getClientRegistrations();
      setWorkedHours(data.hours_week);
      const projects = await ProjectService.getClientProjects(client_id);
      setTotalProjects(projects.length);
      const users = await UserService.getClientUsers(client_id);
      setTotalContributors(users.length);
    })();
  }, []);

  return (
    <Paper className="statistics-container" elevation={3}>
      <h2 className="greeting-header">
        {t('profile-page.hello')}, {first_name}
      </h2>
      <div className="pp-statistics">
        <div className="pp-stat">
          <label className="stat-label">
            {t('profile-page.worked-this-week')}
          </label>
          <p className="statistics-hours-week">{workedHours}</p>
        </div>
        <div className="pp-stat">
          <label className="stat-label">
            {t('profile-page.total-projects')}
          </label>
          <p className="statistics-used-vacation">{totalProjects}</p>
        </div>
        <div className="pp-stat">
          <label className="stat-label">
            {t('profile-page.total-contributors')}
          </label>
          <p className="statistics-left-vacation">{totalContributors}</p>
        </div>
      </div>
    </Paper>
  );
}

export default StatisticsClient;
