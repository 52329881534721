import React from 'react';
import LanguageChoice from '../../components/LanguageChoice';
import DarkMode from '../../components/ModeChoice';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

function Preferences() {
  const { t } = useTranslation('common');
  return (
    <Paper
      elevation={3}
      sx={{ backgroundColor: 'var(--table-bg-color) !important' }}
    >
      <p className="preferrences-header">{t('profile-page.preferrences')}</p>
      <DarkMode />
      <LanguageChoice />
    </Paper>
  );
}

export default Preferences;
