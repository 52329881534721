/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './Dashboard.css';
import HoursRegistration from './HourRegistration/HoursRegistration';
import VacationRegistration from './VacationRegistration/VacationRegistration';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

function RegistrationMenu(props) {
  const [showHours, setShowHours] = useState(true);
  const { t } = useTranslation('common');

  function showHoursForm() {
    setShowHours(true);
  }

  function showVacationForm() {
    setShowHours(false);
  }
  const [alignment, setAlignment] = useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div className="hours-reg">
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{
          backgroundColor: 'var(--blue-color)',
          width: '100%',
          borderRadius: '0px',
          textTransform: 'initial',
          paddingBottom: '5px',
          paddingTop: '5px',
        }}
      >
        <ToggleButton
          value="left"
          aria-label="left aligned"
          size="small"
          sx={{
            marginRight: '10%',
            marginLeft: '30%',
            border: 'none',
            borderRadius: '0px',
            paddingTop: '5px',
            width: '15%',
            marginBottom: '5px',
            '&.Mui-selected': {
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.20) !important',
            },
          }}
          onClick={showHoursForm}
        >
          <AccessTimeIcon sx={{ color: 'white' }} />
        </ToggleButton>
        <ToggleButton
          value="right"
          aria-label="right aligned"
          size="small"
          sx={{
            marginRight: '10%',
            marginLeft: '10%',
            border: 'none',
            borderRadius: '0px',
            width: '15%',
            marginBottom: '5px',
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.20) !important',
            },
          }}
          onClick={showVacationForm}
        >
          <MeetingRoomIcon sx={{ color: 'white' }} />
        </ToggleButton>
      </ToggleButtonGroup>
      <div className="hours-reg-form">
        {showHours === true ? (
          <HoursRegistration
            hoursReg={props.hoursReg}
            onSubmit={props.onSubmit}
            getUserWorkedHours={props.getUserWorkedHours}
          />
        ) : (
          <VacationRegistration
            onSubmitLeaveRequests={props.onSubmitLeaveRequests}
            getPendingLeaveRequests={props.getPendingLeaveRequests}
          />
        )}
      </div>
    </div>
  );
}

export default RegistrationMenu;
