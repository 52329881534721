/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ClientService from '../../services/ClientService';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../components/CloseButton';

function ModalDelete(props) {
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage(await ClientService.removeClient(props.client.id));
    setSubmitted(true);
    props.onSubmit();
  };
  return (
    <Box className="delete-employee-modal">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('clients.confirm')}{' '}
          <CloseButton
            closeModal={props.closeModal}
            style={{ marginTop: '-2%' }}
          />
        </h3>
        <p className="confirm-delete-text">
          {t('clients.delete-client', { name: props.client.name })}
        </p>
        {submitted ? (
          <div className="success-message-delete">{message}</div>
        ) : null}
        <div className="delete-actions">
          <button
            type="submit"
            className="confirm-delete"
            onClick={handleSubmit}
          >
            {t('clients.confirm')}
          </button>
          <button
            type="submit"
            className="discard-delete"
            onClick={props.handleCloseDelete}
          >
            {t('clients.discard')}
          </button>
        </div>
      </form>
    </Box>
  );
}

export default ModalDelete;
