/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import '../Dashboard.css';

import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import CalendarUtils from './CalendarUtils';
import Modal from '@mui/material/Modal';
import ModalInfo from './Modals/ModalInfo';
import ModalLeaveRequest from './Modals/ModalLeaveRequest';
import ModalPendingLeaveRequest from './Modals/ModalPendingLeaveRequest';
import { useTranslation } from 'react-i18next';
import nlLocale from '@fullcalendar/core/locales/nl';
import enLocale from '@fullcalendar/core/locales/en-gb';

function Calendar(props) {
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleCloseInfo = () => {
    setOpenModalInfo(false);
    localStorage.removeItem('event');
  };

  const [openModalLeaveRequest, setOpenModalLeaveRequest] = useState(false);
  const handleCloseLeaveRequest = () => {
    setOpenModalLeaveRequest(false);
    localStorage.removeItem('leave request');
  };

  const [openModalPendingLeaveRequest, setOpenModalPendingLeaveRequest] =
    useState(false);
  const handleClosePendingLeaveRequest = () => {
    setOpenModalPendingLeaveRequest(false);
    localStorage.removeItem('pending leave request');
  };

  const [events, setEvents] = useState([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    void (async () => {
      setEvents(
        await CalendarUtils.showEventsOnCalendar(
          props.hoursReg,
          props.acceptedLeaveRequests,
          props.pendingLeaveRequests
        )
      );
    })();
  }, [props.hoursReg, props.acceptedLeaveRequests, props.pendingLeaveRequests]);

  const buttonText = {
    today: t('calendar.today'),
    month: t('calendar.month'),
    week: t('calendar.week'),
    day: t('calendar.day'),
  };

  function getLocale() {
    return localStorage.getItem('lang') === 'en' ? enLocale : nlLocale;
  }

  return (
    <div className="full-calendar-style">
      <FullCalendar
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        aspectRatio={2.1}
        buttonText={buttonText}
        locale={getLocale()}
        events={events}
        eventBorderColor={'var(--blue-color)'}
        eventDisplay={'auto'}
        eventClick={function (arg) {
          const groupId = arg.event._def.groupId;
          if (groupId === '1') {
            localStorage.setItem('event', arg.event.id);
            setOpenModalInfo(true);
          } else if (groupId === '2' || groupId === '3') {
            localStorage.setItem('leave request', arg.event.id);
            setOpenModalLeaveRequest(true);
          } else {
            localStorage.setItem('pending leave request', arg.event.id);
            setOpenModalPendingLeaveRequest(true);
          }
        }}
        eventContent={function (arg) {
          const groupId = arg.event._def.groupId;
          if (groupId === '1') {
            return <p className="event-calendar">{arg.event.title}</p>;
          } else if (groupId === '2') {
            return <p className="vacation-calendar">{arg.event.title}</p>;
          } else if (groupId === '3') {
            return <p className="multiple-calendar">{arg.event.title}</p>;
          } else if (groupId === '4') {
            return <p className="pending-single-day">{arg.event.title}</p>;
          } else if (groupId === '5') {
            return <p className="pending-multiple-day">{arg.event.title}</p>;
          }
        }}
      />
      {'event' in localStorage ? (
        <Modal
          open={openModalInfo}
          onClose={handleCloseInfo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          useNativeDriver={true}
        >
          <ModalInfo
            id={localStorage.getItem('event')}
            closeModal={handleCloseInfo}
            onSubmit={props.onSubmit}
            getUserWorkedHours={props.getUserWorkedHours}
            setSnackbarMessage={props.setSnackbarMessage}
            setSnackbarSeverity={props.setSnackbarSeverity}
            openSnackbar={props.openSnackbar}
            isAdmin={false}
          />
        </Modal>
      ) : 'leave request' in localStorage ? (
        <Modal
          open={openModalLeaveRequest}
          onClose={handleCloseLeaveRequest}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          useNativeDriver={true}
        >
          <ModalLeaveRequest
            id={localStorage.getItem('leave request')}
            closeModal={handleCloseLeaveRequest}
          />
        </Modal>
      ) : (
        <Modal
          open={openModalPendingLeaveRequest}
          onClose={handleClosePendingLeaveRequest}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          useNativeDriver={true}
        >
          <ModalPendingLeaveRequest
            id={localStorage.getItem('pending leave request')}
            getUserRequests={props.getUserRequests}
            closeModal={handleClosePendingLeaveRequest}
          />
        </Modal>
      )}
    </div>
  );
}

export default Calendar;
