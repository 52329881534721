/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import UserManagement from '../UserManagement/UserManagement';
import AuthenticationService from '../services/AuthenticationService';
import './AdminDashboard.css';
import { useNavigate } from 'react-router-dom';
import ProjectsManagement from '../ProjectManagement/ProjectsManagement';
import ClientManagement from '../Client Management/ClientManagement';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../TableUtils/TabPanel';
import PendingLeaveRequests from '../LeaveRequestManagement/PendingLeaveRequests';
import '../LeaveRequestManagement/LeaveRequestManagement.css';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import { PendingLeaveRequest } from '../types/PendingLeaveRequest';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AdminDashboard(props: {
  logOut: () => void;
  pendingLeaveRequests: PendingLeaveRequest[];
  getPendingLeaveRequests: () => void;
}) {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(false);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    try {
      const checkIfAdmin: boolean | undefined =
        AuthenticationService.checkIfAdmin();
      setIsAdmin(checkIfAdmin);
    } catch (error: unknown) {
      console.error('Error: ' + (error as Error).message);
    }
  }, [navigate, props.logOut]);

  return (
    <Page>
      <div className="admin-dashboard">
        {isAdmin ? (
          <div className="admin">
            <div className="admin-dash-containers">
              <PendingLeaveRequests
                pendingLeaveRequests={props.pendingLeaveRequests}
                getPendingLeaveRequests={props.getPendingLeaveRequests}
              />
              <Box className="admin-tabview">
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    style={{ backgroundColor: 'var(--blue-color)' }}
                  >
                    <Tab
                      label={t('admin-dashboard.employees')}
                      {...a11yProps(0)}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato',
                      }}
                    />
                    <Tab
                      label={t('admin-dashboard.projects')}
                      {...a11yProps(1)}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato',
                      }}
                    />
                    <Tab
                      label={t('admin-dashboard.clients')}
                      {...a11yProps(2)}
                      style={{
                        color: 'white',
                        fontFamily: 'Lato',
                      }}
                    />
                  </Tabs>
                </Box>
                <TabPanel className="tab-panel-admin" value={value} index={0}>
                  <UserManagement />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ProjectsManagement />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ClientManagement />
                </TabPanel>
              </Box>
            </div>
          </div>
        ) : (
          <p className="not-logged-in">{t('commons.not-allowed')}</p>
        )}
      </div>
    </Page>
  );
}

export default AdminDashboard;
