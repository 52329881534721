/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import InputGroup from '../../components/InputGroup';
import SubmitButton from '../../components/SubmitButton';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/UserService';
import CloseButton from '../../components/CloseButton';

function ModalAddPerson({ client_id, closeModal }) {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation('common');

  const handleFirstNameInputChange = (event) => {
    setValues({ ...values, firstName: event.target.value });
  };

  const handleLastNameInputChange = (event) => {
    setValues({ ...values, lastName: event.target.value });
  };

  const handleEmailInputChange = (event) => {
    setValues({ ...values, email: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.firstName && values.lastName && values.email) {
      setValid(true);
      const response = await UserService.addNewUser(
        values.firstName,
        values.lastName,
        values.email,
        0,
        null,
        '',
        client_id,
        2
      );
      if (response.created === false) {
        setValid(false);
        setMessage(response.message.email[0]);
      } else {
        setMessage(response.message);
      }
    } else {
      setMessage('Please enter all the details!');
    }

    setSubmitted(true);
  };

  return (
    <Box className="add-employee-box">
      <form className="modal-form" onSubmit={handleSubmit}>
        <h3 className="new-employee">
          {t('clients.add-client-person')}{' '}
          <CloseButton closeModal={closeModal} />
        </h3>
        {submitted && !valid ? (
          <div className="error-message-add-employee">{message}</div>
        ) : null}
        <InputGroup
          id="add-first-name"
          label={t('users.first-name')}
          onChange={handleFirstNameInputChange}
          value={values.firstName}
          name="add-first-name"
          type="text"
          placeholder="Enter the first name"
        />
        <InputGroup
          id="add-last-name"
          label={t('users.last-name')}
          onChange={handleLastNameInputChange}
          value={values.lastName}
          name="add-last-name"
          type="text"
          placeholder="Enter the last name"
        />
        <InputGroup
          id="add-email"
          label={t('users.email')}
          onChange={handleEmailInputChange}
          value={values.email}
          name="add-email"
          type="text"
          placeholder="Enter the e-mail address"
        />
        <SubmitButton text={t('users.button-add')} />
      </form>
    </Box>
  );
}

export default ModalAddPerson;
