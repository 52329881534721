import React, { useState } from 'react';
import LoginInput from '../components/LoginInput';
import UserService from '../services/UserService';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation('common');

  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      const response = await UserService.forgottenPassword(email);
      if (response.success === true) {
        setValid(true);
        setMessage(
          'If there is a user with the provided email address, an email with a new password has been sent to them.'
        );
      } else {
        setValid(false);
        setMessage(response.message);
      }
    } else {
      setMessage('Please enter the email address!');
    }
    setSubmitted(true);
  };

  return (
    <div className="login">
      <div className="blue-header"></div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-text">
          <h1>{t('forgot-password.header')}</h1>
          {submitted && valid ? (
            <div className="fp-message">{message}</div>
          ) : null}
          {submitted && !valid ? (
            <div className="login-message">{message}</div>
          ) : null}
        </div>
        <LoginInput
          id="login-email"
          label={t('forgot-password.email')}
          onChange={handleEmailInputChange}
          value={email}
          name="login-email"
          type="text"
          placeholder={'voorbeeld@simdo.nl'}
        />
        <div className="login-action">
          <button type="submit" className="forgot-button">
            {t('forgot-password.button-text')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
