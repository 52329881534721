const options = [
  {
    value: 1,
    label: 'Title (A to Z)',
    field: 'title',
    type: 'string',
  },
  {
    value: 2,
    label: 'Jira Link (A to Z)',
    field: 'jira_link',
    type: 'string',
  },
  {
    value: 3,
    label: 'GitHub Link (A to Z)',
    field: 'repo_link',
    type: 'string',
  },
  {
    value: 4,
    label: 'Worked Hours (most to least)',
    field: 'worked_hours',
    type: 'int',
  },
  {
    value: 5,
    label: 'Left hours (most to least)',
    field: 'left_hours',
    type: 'array',
  },
  {
    value: 6,
    label: 'Users contributing to the project (most to least)',
    field: 'project_users',
    type: 'array',
  },
];

export default options;
